import advTitle from "@/page/advSchool/advTitle";
import adbCtx1 from "@/page/advSchool/adbCtx1";
import advTitle2 from "@/page/advSchool/advTitle2";
import adbCtx2 from "@/page/advSchool/adbCtx2";
import adbCtx3 from "@/page/advSchool/adbCtx3";
import adbCtx4 from "@/page/advSchool/adbCtx4";
import adbCtx5 from "@/page/advSchool/adbCtx5";
import adbCtx6 from "@/page/advSchool/adbCtx6";
import adbCtx7 from "@/page/advSchool/adbCtx7";
import adbCtx8 from "@/page/advSchool/adbCtx8";
import adbCtx9 from "@/page/advSchool/adbCtx9";
import adbCtx10 from "@/page/advSchool/adbCtx10";
export default {
  name: "aodaoliya",
  components: {
    advTitle,
    advTitle2,
    adbCtx1,
    adbCtx2,
    adbCtx3,
    adbCtx4,
    adbCtx5,
    adbCtx6,
    adbCtx7,
    adbCtx8,
    adbCtx9,
    adbCtx10
  }
};