import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "page-margin"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CWZTitle = _resolveComponent("CWZTitle");
  const _component_CHtmlPanel = _resolveComponent("CHtmlPanel");
  const _component_CComment = _resolveComponent("CComment");
  const _component_CBChat = _resolveComponent("CBChat");
  const _component_PBottom = _resolveComponent("PBottom");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_CWZTitle, {
    info: $data.info
  }, null, 8, ["info"]), _createVNode(_component_CHtmlPanel, {
    url: $options.getHtml
  }, null, 8, ["url"]), _createVNode(_component_CComment, {
    ref: "CComment",
    info: $data.info
  }, null, 8, ["info"]), _createVNode(_component_CBChat, {
    isCollection: $data.isCollection,
    isFromHome: $data.isFromHome,
    onCollectionDaily: $options.onCollectionDaily,
    onCommentDaily: $options.onCommentDaily
  }, null, 8, ["isCollection", "isFromHome", "onCollectionDaily", "onCommentDaily"]), _createVNode(_component_PBottom)]);
}