/**
 * @param date {Date}
 * @param fmt {string}
 * @return {string}
 */
function toDate(date = new Date(), fmt = 'yyyy-MM-dd hh:mm:ss') {
  let o = {
    "M+": date.getMonth() + 1,                 //月份
    "d+": date.getDate(),                    //日
    "h+": date.getHours(),                   //小时
    "m+": date.getMinutes(),                 //分
    "s+": date.getSeconds(),                 //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    "S": date.getMilliseconds()             //毫秒
  };

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  }

  for (let k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    }
  }
  return fmt;
}

/**
 * @param src {Object}
 * @param dst {Object}
 */
function fillData(src, dst) {
  for (let key in dst){
    // eslint-disable-next-line no-prototype-builtins
    if (src.hasOwnProperty(key)) {
      dst[key] = src[key]
    }
  }

}

/**
 * @param timestamp {number}
 * @return {Promise<void>}
 */
function delay(timestamp) {
  return new Promise(resolve => {
    setTimeout(() => resolve(), timestamp)
  })
}

/**
 * 和PHP一样的时间戳格式化函数
 * @param {string} format 格式
 * @param {int} timestamp 要格式化的时间 默认为当前时间
 * @return {string}   格式化的时间字符串
 */
function date(format, timestamp){
  if(timestamp==0)
    return '0'
  var jsdate=((timestamp) ? new Date(timestamp*1000) : new Date());
  var pad = function(n, c){
    if((n = n + "").length < c){
      return new Array(++c - n.length).join("0") + n;
    } else {
      return n;
    }
  };
  var txt_weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  var txt_ordin = {1:"st", 2:"nd", 3:"rd", 21:"st", 22:"nd", 23:"rd", 31:"st"};
  var txt_months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  var f = {
    // Day
    d: function(){return pad(f.j(), 2)},
    D: function(){return f.l().substr(0,3)},
    j: function(){return jsdate.getDate()},
    l: function(){return txt_weekdays[f.w()]},
    N: function(){return f.w() + 1},
    S: function(){return txt_ordin[f.j()] ? txt_ordin[f.j()] : 'th'},
    w: function(){return jsdate.getDay()},
    z: function(){return (jsdate - new Date(jsdate.getFullYear() + "/1/1")) / 864e5 >> 0},

    // Week
    W: function(){
      var a = f.z(), b = 364 + f.L() - a;
      var nd2, nd = (new Date(jsdate.getFullYear() + "/1/1").getDay() || 7) - 1;
      if(b <= 2 && ((jsdate.getDay() || 7) - 1) <= 2 - b){
        return 1;
      } else{
        if(a <= 2 && nd >= 4 && a >= (6 - nd)){
          nd2 = new Date(jsdate.getFullYear() - 1 + "/12/31");
          return date("W", Math.round(nd2.getTime()/1000));
        } else{
          return (1 + (nd <= 3 ? ((a + nd) / 7) : (a - (7 - nd)) / 7) >> 0);
        }
      }
    },

    // Month
    F: function(){return txt_months[f.n()]},
    m: function(){return pad(f.n(), 2)},
    M: function(){return f.F().substr(0,3)},
    n: function(){return jsdate.getMonth() + 1},
    t: function(){
      var n;
      if( (n = jsdate.getMonth() + 1) == 2 ){
        return 28 + f.L();
      } else{
        if( n & 1 && n < 8 || !(n & 1) && n > 7 ){
          return 31;
        } else{
          return 30;
        }
      }
    },

    // Year
    L: function(){var y = f.Y();return (!(y & 3) && (y % 1e2 || !(y % 4e2))) ? 1 : 0},
    //o not supported yet
    Y: function(){return jsdate.getFullYear()},
    y: function(){return (jsdate.getFullYear() + "").slice(2)},

    // Time
    a: function(){return jsdate.getHours() > 11 ? "pm" : "am"},
    A: function(){return f.a().toUpperCase()},
    B: function(){
      // peter paul koch:
      var off = (jsdate.getTimezoneOffset() + 60)*60;
      var theSeconds = (jsdate.getHours() * 3600) + (jsdate.getMinutes() * 60) + jsdate.getSeconds() + off;
      var beat = Math.floor(theSeconds/86.4);
      if (beat > 1000) beat -= 1000;
      if (beat < 0) beat += 1000;
      if ((String(beat)).length == 1) beat = "00"+beat;
      if ((String(beat)).length == 2) beat = "0"+beat;
      return beat;
    },
    g: function(){return jsdate.getHours() % 12 || 12},
    G: function(){return jsdate.getHours()},
    h: function(){return pad(f.g(), 2)},
    H: function(){return pad(jsdate.getHours(), 2)},
    i: function(){return pad(jsdate.getMinutes(), 2)},
    s: function(){return pad(jsdate.getSeconds(), 2)},
    //u not supported yet

    // Timezone
    //e not supported yet
    //I not supported yet
    O: function(){
      var t = pad(Math.abs(jsdate.getTimezoneOffset()/60*100), 4);
      if (jsdate.getTimezoneOffset() > 0) t = "-" + t; else t = "+" + t;
      return t;
    },
    P: function(){var O = f.O();return (O.substr(0, 3) + ":" + O.substr(3, 2))},
    //T not supported yet
    //Z not supported yet

    // Full Date/Time
    c: function(){return f.Y() + "-" + f.m() + "-" + f.d() + "T" + f.h() + ":" + f.i() + ":" + f.s() + f.P()},
    //r not supported yet
    U: function(){return Math.round(jsdate.getTime()/1000)}
  };

  return format.replace(/[0-9]?([a-zA-Z])/g,function(t, s){
    var ret;
    if( t!=s ){
      // escaped
      ret = s;
    } else if( f[s] ){
      // a date function exists
      ret = f[s]();
    } else{
      // nothing special
      ret = s;
    }
    return ret;
  });
}


//转换为天和时间
function transformDataTime(remainTime){
  var out = '';
  var tmp;
  out += parseInt(remainTime/(3600*24))
  out += '天'
  tmp = parseInt(remainTime%(3600*24)/3600)
  if(tmp<10)out += '0'
  out += tmp;
  out += ':'
  tmp = parseInt(remainTime%(3600)/60)
  if(tmp<10)out += '0'
  out += tmp;
  out += ':'
  tmp = parseInt(remainTime%(60))
  if(tmp<10)out += '0'
  out += tmp;
  return out
}

function makeBlobFileName(blob){
  // console.log(blob)
  var fileType = blob.type
  // console.log(fileType);
  const objectKey = `img_${new Date().getTime()}.${fileType.split('/').pop()}`;
  // console.log(objectKey);
  return objectKey
}

function checkModbile(mobile) {
  var re = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
  var result = re.test(mobile);
  if(!result) {
    // alert("手机号码格式不正确！");
    return false;//若手机号码格式不正确则返回false
  }
  return true;
}

export const utils = {
  toDate, fillData, delay, date, transformDataTime, makeBlobFileName,

  checkModbile,
};
