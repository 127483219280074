import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CLine = _resolveComponent("CLine");
  const _component_CR04Name = _resolveComponent("CR04Name");
  const _component_van_list = _resolveComponent("van-list");
  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
  const _component_PBottom = _resolveComponent("PBottom");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_CLine), _createVNode(_component_van_pull_refresh, {
    modelValue: _ctx.refreshing,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.refreshing = $event),
    onRefresh: _ctx.onRefresh
  }, {
    default: _withCtx(() => [_createVNode(_component_van_list, {
      loading: _ctx.loading,
      "onUpdate:loading": _cache[0] || (_cache[0] = $event => _ctx.loading = $event),
      finished: _ctx.finished,
      "finished-text": "没有更多了",
      onLoad: _ctx.onLoad
    }, {
      default: _withCtx(() => [_createVNode(_component_CR04Name, {
        dailies: _ctx.dailies
      }, null, 8, ["dailies"])]),
      _: 1
    }, 8, ["loading", "finished", "onLoad"])]),
    _: 1
  }, 8, ["modelValue", "onRefresh"]), _createVNode(_component_PBottom)], 64);
}