import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2b39e483"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "context"
};
const _hoisted_2 = ["src", "onClick"];
const _hoisted_3 = {
  class: "botTitle"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FCom = _resolveComponent("FCom");
  return _openBlock(), _createBlock(_component_FCom, {
    title: $props.title
  }, {
    context: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.detail, (item, index) => {
      return _openBlock(), _createElementBlock("div", {
        class: "item",
        key: index
      }, [_createElementVNode("img", {
        class: "img",
        src: $options.getImgUrl(item),
        alt: "",
        onClick: $event => $options.onClick(item)
      }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, _toDisplayString(item.daily.title), 1)]);
    }), 128))])]),
    _: 1
  }, 8, ["title"]);
}