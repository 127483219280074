import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "position": "relative",
    "right": "0.5rem",
    "width": "1rem",
    "height": "1rem",
    "border-radius": "50%"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CUploadImg = _resolveComponent("CUploadImg");
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_cell_group = _resolveComponent("van-cell-group");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_cell_group, {
    inset: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_van_field, {
      readonly: "",
      "input-align": "right",
      center: "",
      name: "个人头像",
      label: "个人头像："
    }, {
      input: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_CUploadImg, {
        ref: "CUploadImg",
        proImgSrc: $options.getImg
      }, null, 8, ["proImgSrc"])])]),
      _: 1
    }), _createVNode(_component_van_field, {
      modelValue: $data.mInfo.userName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.mInfo.userName = $event),
      "is-link": "",
      "input-align": "right",
      label: "用户名：",
      name: "用户名"
    }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
      modelValue: $data.mInfo.userId,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.mInfo.userId = $event),
      "input-align": "right",
      readonly: "",
      name: "用户ID",
      label: "用户ID："
    }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
      modelValue: $data.mInfo.identifier,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.mInfo.identifier = $event),
      "input-align": "right",
      readonly: "",
      label: "手机号：",
      name: "手机号"
    }, null, 8, ["modelValue"])]),
    _: 1
  })]);
}