
import wx from "weixin-js-sdk";
import {httpWx} from "@/js/http/httpWx";

//=================================================================================
//以下是dany服务接口
//=================================================================================
//微信分享
//获取JS-SDK使用权限签名算法包
/*
* "appId":"wx0436c2e9ea60fce2",
* "nonceStr":"KqeYVlF9IFH5E1Mi",
* "timestamp":1640414229,
* "url":"https:\/\/tddev.toodo.com.cn\/wxp\/wx\/api\/getSignPack",
* "signature":"659a3f7aaf3473ded8ae587349b93e8c6ba6ad9d",
* "errcode":0 （失败非0）
 */
export function getWXSignPack(method='123', bizContent={}) {
    return httpWx({
        url: '/wx/api/getSignPack',
        data: {
            method,
            bizContent
        }
    })
}

//获取带参二维码
function getWXQrcode(method='123', bizContent={}) {
    return httpWx({
        url: '/wx/public/qrcodeCreate',
        data: {
            method,
            bizContent
        }
    })
}

//标题
//描述
//分享链接地址
//logo图片链接地址
export function shareWXInit(title, desc, link, imgUrl){

    // imgUrl = 'https://kepu.oss.toodo.com.cn/activity/data/shareLogo.png'
    imgUrl = 'https://kepu.oss.toodo.com.cn/kxzz-edu/data/logo.png'

    let method = window.location.href
    let bizContent = {
        link: link
    }
    getWXSignPack(method, bizContent).then(res=>{
        console.log("getWXSignPack:")
        console.log(res)
        var showStr = JSON.stringify(res)
        // alert("myres:"+showStr)

        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.appId, // 必填，公众号的唯一标识
            timestamp: res.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.nonceStr, // 必填，生成签名的随机串
            signature: res.signature,// 必填，签名
            jsApiList: ['checkJsApi',
                'updateAppMessageShareData',
                'updateTimelineShareData',
                'hideMenuItems'] // 必填，需要使用的JS接口列表
        });

        wx.ready(function(){
            wx.checkJsApi({
                jsApiList: [
                    'updateAppMessageShareData',
                    'updateTimelineShareData',
                    'hideMenuItems'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                success: function(res) {
                    // alert(res)
                },
                fail: function(res) {
                },
            });

            wx.updateTimelineShareData({
                title: desc, // 分享标题
                link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: imgUrl, // 分享图标
                success: function () {
                    // 设置成功
                    // alert('微信分享到朋友设置成功333')
                },
                fail: function (){
                    // alert('微信分享失败333')
                }
            });

            wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: desc, // 分享描述
                link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: imgUrl, // 分享图标
                success: function () {
                    // 设置成功
                    // alert('微信分享到朋友设置成功22')
                },
                fail: function (){
                     // alert('微信分享失败222')
                }
            });

            wx.hideMenuItems({
                menuList: [] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有 menu 项见附录3
            });
        });
        wx.error(function(res){
            // 1600 123config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
    }).catch(err=>{

    })
}
export function shareWXUnInit(){

    // let imgUrl = 'https://kepu.oss.toodo.com.cn/activity/data/shareLogo.png'

    let method = window.location.href
    let bizContent = {
        link: window.location.href
    }

    getWXSignPack(method, bizContent).then(res=>{
        // console.log(res)
        var showStr = JSON.stringify(res)
        // alert("myres:"+showStr)

        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.appId, // 必填，公众号的唯一标识
            timestamp: res.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.nonceStr, // 必填，生成签名的随机串
            signature: res.signature,// 必填，签名
            jsApiList: ['checkJsApi',
                'updateAppMessageShareData',
                'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });

        wx.ready(function(){
            wx.checkJsApi({
                jsApiList: [
                    'updateAppMessageShareData',
                    'updateTimelineShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                success: function(res) {
                },
                fail: function(res) {
                },
            });

            wx.updateTimelineShareData({
                title: "嗨科普", // 分享标题
                link: window.location.origin+"/null", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: "null", // 分享图标
                success: function () {
                    // 设置成功
                    // alert('微信分享到朋友设置成功333')
                },
                fail: function (){
                    // alert('微信分享失败333')
                }
            });

            wx.updateAppMessageShareData({
                title: "嗨科普", // 分享标题
                desc: "投票页不能再分享", // 分享描述
                link: window.location.origin+"/null", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: "null", // 分享图标
                success: function () {
                    // 设置成功
                    // alert('微信分享到朋友设置成功22')
                },
                fail: function (){
                    // alert('微信分享失败222')
                }
            })
        });
        wx.error(function(res){
            // 1600 123config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
    }).catch(err=>{

    })
}

//获取公众号带参二维码
//标题
//描述
//logo图片链接地址
//分享链接地址
export function shareGetQrcode(actId, userId, title, describe, picUrl, linkUrl){
    let p = new Promise((resolve,reject)=> {
        let method = window.location.href
        let bizContent = {
            actId, userId, title, describe, picUrl, linkUrl
        }
        getWXQrcode(method, bizContent).then(res=>{
            console.log('成功:获取公从号二维码!');
            console.log(res)
            resolve(res)
        }).catch(err=>{
            console.log('***错误:获取参公从号二维码!');
            reject({code: err.code, msg:'错误:获取公从号二维码!'})
        }).finally(()=>{})
    })
    return p
}

export function openLaunchInit(title, desc, link, imgUrl){
    // imgUrl = 'https://kepu.oss.toodo.com.cn/activity/data/shareLogo.png'
    let method = window.location.href
    let bizContent = {
        link: window.location.href
    }

    getWXSignPack(method, bizContent).then(res=>{
        var showStr = JSON.stringify(res)
        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.appId, // 必填，公众号的唯一标识
            timestamp: res.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.nonceStr, // 必填，生成签名的随机串
            signature: res.signature,// 必填，签名
            jsApiList: ['checkJsApi',
                'updateAppMessageShareData',
                'updateTimelineShareData',
                'hideMenuItems'], // 必填，需要使用的JS接口列表
            openTagList: ['wx-open-launch-app'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
        });

        wx.ready(function(){
            wx.checkJsApi({
                jsApiList: [
                    'updateAppMessageShareData',
                    'updateTimelineShareData',
                    'hideMenuItems'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                success: function(res) {
                },
                fail: function(res) {
                },
            });

            wx.updateTimelineShareData({
                title: desc, // 分享标题
                link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: imgUrl, // 分享图标
                success: function () {
                    // 设置成功
                    // alert('微信分享到朋友设置成功333')
                },
                fail: function (){
                    // alert('微信分享失败333')
                }
            });

            wx.updateAppMessageShareData({
                title: title, // 分享标题
                desc: desc, // 分享描述
                link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: imgUrl, // 分享图标
                success: function () {
                    // 设置成功
                    //  alert('微信分享到朋友设置成功22')
                },
                fail: function (){
                    // alert('微信分享失败222')
                }
            });

            wx.hideMenuItems({
                menuList: [] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有 menu 项见附录3
            });
        });
        wx.error(function(res){
            // 1600 123config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
    }).catch(err=>{

    })
}