import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-dfd267f2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "page-margin paddingLR"
};
const _hoisted_2 = {
  class: "left"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "keywords"
};
const _hoisted_5 = {
  class: "time"
};
const _hoisted_6 = {
  key: 0,
  class: "span"
};
const _hoisted_7 = {
  key: 1
};
const _hoisted_8 = {
  class: "right"
};
const _hoisted_9 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "context",
    onClick: _cache[0] || (_cache[0] = (...args) => _ctx.onClick && _ctx.onClick(...args))
  }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keywords, (item, index) => {
    return _openBlock(), _createElementBlock("span", {
      class: "keyword",
      key: index
    }, _toDisplayString(item), 1);
  }), 128))]), _createElementVNode("div", _hoisted_5, [_createTextVNode(_toDisplayString(_ctx.author) + " ", 1), _ctx.isShowTime ? (_openBlock(), _createElementBlock("span", _hoisted_6, "发布时间：")) : _createCommentVNode("", true), _ctx.isShowTime ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.time), 1)) : _createCommentVNode("", true)])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("img", {
    class: "img",
    src: _ctx.img,
    alt: ""
  }, null, 8, _hoisted_9)])])]);
}