import {login} from "@/js/login";
import {showToast} from "vant";

let eventP = null

function eventInit(that){
    // console.log("eventInit")
    // console.log(that)
    eventP = that
}

function eventUnInit(){
    // console.log("eventUnInit")
    eventP = null
}

//eventP为PHead组件服务
function eventLogin(params){
    if(eventP==null){
        return
    }
    // console.log("eventLogin")
    eventP.$bus.emit("onLogin",params)
}

function eventGetToken(){
    if(eventP==null){
        return 'undefined'
    }
    return eventP.$userInfo.token
}

function eventRefreshToken(token){
    if(eventP==null){
        return
    }
    eventP.$userInfo.token = token
}

//登录失效，通过session重新获取token
function eventSessionGetToken(){
    if(eventP==null){
        return
    }
    if(eventP.$userInfo.isLogin){
        login.bgGetToken({
            session: eventP.$userInfo.session
        }).then(res=>{
            eventP.$userInfo.token = res.token
        })
    }else{
        eventLogin()
    }

}

export {eventInit,eventUnInit,
    eventLogin,
    eventGetToken,
    eventRefreshToken,
    eventSessionGetToken}