import advTitle2 from "@/page/advSchool/advTitle2";
import advTitle from "@/page/advSchool/advTitle";
import adjCtx1 from "@/page/advSchool/adjCtx1";
import adjCtx2 from "@/page/advSchool/adjCtx2";
import adjCtx3 from "@/page/advSchool/adjCtx3";
import adjCtx4 from "@/page/advSchool/adjCtx4";
import adjCtx5 from "@/page/advSchool/adjCtx5";
export default {
  name: "jialada",
  components: {
    advTitle,
    advTitle2,
    adjCtx1,
    adjCtx2,
    adjCtx3,
    adjCtx4,
    adjCtx5
  },
  data() {
    return {
      bgColor: 1
    };
  }
};