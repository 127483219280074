import videojs from "video.js";
import { myMediaConfig } from "./myMediaConfig";
import { CompLiveText } from "./compLiveText";
import { CompDragTime } from "./compDragTime";
import { CompLogo } from "./compLogo";
import { CompLookBack } from "./compLookBack";
import { compTitleBar } from "./compTitleBar";
export default {
  name: "CMediaPlayer",
  props: {
    isLive: {
      type: Boolean,
      default: false
    },
    isLogo: {
      type: Boolean,
      default: false
    },
    isLookBack: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    },
    poster: {
      type: String,
      default: ''
    },
    isPosterFill: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      myPlayer: null,
      //内置组件
      ControlBar: null,
      //自定义组件
      myDragTime: null,
      myLiveText: null,
      myLogo: null,
      myLookBack: null,
      myTitleBar: null
    };
  },
  beforeUnmount() {
    this.myPlayer.dispose();
  },
  mounted() {
    console.log("media player mounted");
    let that = this;
    this.myPlayer = videojs(this.$refs.myVideoPlayer, myMediaConfig.normalOptions, function onPlayerReady() {
      that.onReady(this);
    });
    //创建自定义组件
    this.myLiveText = new CompLiveText(this.myPlayer);
    this.myDragTime = new CompDragTime(this.myPlayer);
    this.myLogo = new CompLogo(this.myPlayer);
    this.myLookBack = new CompLookBack(this.myPlayer);
    this.myTitleBar = compTitleBar(this.myPlayer, this.title, 'BigPlayButton');
    this.ControlBar = this.myPlayer.getChild('ControlBar');

    //标题
    this.addChild(this.myPlayer, this.myTitleBar, "BigPlayButton");
    //回顾
    if (this.isLookBack) {
      this.addChild(this.myPlayer, this.myLookBack, "BigPlayButton");
    }
    //APP logo
    if (this.isLogo) {
      this.addChild(this.myPlayer, this.myLogo, "BigPlayButton");
    }
    //是否直播
    if (this.isLive) {
      this.removeChild(this.ControlBar, "PlayToggle");
      this.removeChild(this.ControlBar, "ProgressControl");
      this.removeChild(this.ControlBar, "DurationDisplay");
      this.addChild(this.ControlBar, this.myLiveText, "CurrentTimeDisplay");
    } else {
      this.addChild(this.myPlayer, this.myDragTime, "BigPlayButton");
    }
    if (this.isPosterFill) {
      this.myPlayer.addClass("vjs-poster-fill");
    }
    this.setPoster(this.poster);
    if (this.src != "") {
      this.setSrc(this.src);
      this.load();
      this.pause();
      if (!this.myPlayer.hasClass("vjs-pause")) {
        this.myPlayer.addClass("vjs-pause");
      }
    } else {
      this.myPlayer.addClass("vjs-error");
    }
  },
  watch: {
    isLive: {
      handler(newVal, oldVal) {
        if (newVal) {
          //先删
          this.removeChild(this.ControlBar, "PlayToggle");
          this.removeChild(this.ControlBar, "ProgressControl");
          this.removeChild(this.ControlBar, "DurationDisplay");
          this.removeChild(this.myPlayer, this.myDragTime);
          //后加
          this.addChild(this.ControlBar, this.myLiveText, "CurrentTimeDisplay");
        } else {
          //先删
          this.removeChild(this.ControlBar, this.myLiveText);

          //后加
          this.addChild(this.ControlBar, "DurationDisplay", "VolumePanel");
          this.addChild(this.ControlBar, "ProgressControl", "DurationDisplay");
          this.addChild(this.ControlBar, "PlayToggle", "CurrentTimeDisplay ");
          this.addChild(this.myPlayer, this.myDragTime, "BigPlayButton");
        }
      }
    },
    isLogo: {
      handler(newVal, oldVal) {
        if (newVal) {
          //加
          this.addChild(this.myPlayer, this.myLogo, "BigPlayButton");
        } else {
          //删
          this.removeChild(this.myPlayer, this.myLogo);
        }
      }
    },
    isLookBack: {
      handler(newVal, oldVal) {
        if (newVal) {
          //加
          this.addChild(this.myPlayer, this.myLookBack, "BigPlayButton");
        } else {
          //删
          this.removeChild(this.myPlayer, this.myLookBack);
        }
      }
    },
    title: {
      handler(newVal, oldVal) {
        this.myTitleBar.setTitle(newVal);
      }
    },
    src: {
      handler(newVal, oldVal) {
        if (newVal == '') {
          this.pause();
          this.setSrc("mp4");
          this.load();
        } else {
          if (this.myPlayer.hasClass("vjs-error")) {
            this.myPlayer.removeClass("vjs-error");
          }
          if (!this.myPlayer.hasClass("vjs-pause")) {
            this.myPlayer.addClass("vjs-pause");
          }
          this.pause();
          this.setSrc(newVal);
          this.load();
        }
      }
    },
    poster: {
      handler(newVal, oldVal) {
        this.setPoster(newVal);
      }
    }
  },
  methods: {
    addChild(fatherComp, childComp, indexName = '') {
      let index = 0;
      if (indexName != '') {
        index = fatherComp.children().indexOf(fatherComp.getChild(indexName));
        fatherComp.addChild(childComp, {}, index);
      } else {
        fatherComp.addChild(childComp);
      }
    },
    removeChild(fatherComp, childComp) {
      fatherComp.removeChild(childComp);
    },
    onReady(that) {
      that.on("error", () => {
        //视频错误监听事件
        // console.log(this.currentSrc())
        // console.log("视频错误监听事件")
        // if(this.currentSrc()==''){
        // }
      });
      that.on("loadstart", () => {
        //视频开始加载数据监听事件
        // console.log("视频开始加载数据监听事件")
      });
      that.on("canplaythrough", () => {
        //视频可以播放监听事件
        // console.log(that.hasClass("vjs-has-started"))
      });
      that.on("playing", () => {
        //  视频正在播放监听事件
        // console.log("视频正在播放监听事件")
      });
      that.on("stalled", () => {
        console.log("网络异常");
      });
      that.on("waiting", () => {
        //视频正在加载事件
        // console.log("视频正在加载事件")
      });
    },
    setSrc(url) {
      if (this.isLive) {
        this.myPlayer.src({
          src: url,
          type: 'application/x-mpegURL'
        });
      } else {
        this.myPlayer.src(url);
      }
    },
    setPoster(url) {
      this.myPlayer.poster(url);
    },
    play() {
      this.myPlayer.play();
    },
    pause() {
      this.myPlayer.pause();
    },
    dispose() {
      this.myPlayer.dispose();
    },
    load() {
      if (this.myPlayer.hasClass("vjs-has-started")) {
        this.myPlayer.removeClass("vjs-has-started");
      }
      this.myPlayer.load();
    },
    getStatus() {}
  }
};