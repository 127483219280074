import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-55f7c8a2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  ref: "CLDTitle"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
    title: $props.title,
    onClickRight: $options.onClickRight
  }, {
    right: _withCtx(() => [_createVNode(_component_van_icon, {
      name: "cross",
      size: "0.6rem",
      color: "var(--bg-color)"
    })]),
    _: 1
  }, 8, ["title", "onClickRight"])], 512);
}