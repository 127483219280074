import {login} from "@/js/login";
import {evnConfig} from "@/js/evnConfig";

export default function userInfo(){

    this.isLogin = false //是否已经登录
    this.lastLoginTime = null //上次登录时间截
    this.token = 'no' //登录token (string)
    this.session = null //持久化登录标志 (string)
    this.status = 1  //用户状态(1.正常 2.封禁 3.注销 4.封禁主页)    (unsignedTinyInteger)
    this.userId = null
    this.userName = null
    this.userImg = null

    this.identifier = null //唯一标识 手机号  (string)
    this.passwordMd5 = null //密码MD5  (string)

    //从浏览器上更新数据
    this.loadFromStorage = function (that){
        this.identifier = localStorage.getItem("identifier")
        this.passwordMd5 = localStorage.getItem("passwordMd5")
        this.lastLoginTime = localStorage.getItem("lastLoginTime")

        //尝试登录
        this.tryLogin(that)
    }

    //登录成功、注册成功、修改密码成功
    //1. 更新一下登录时间，用于下次自动登录用
    //2. 更新一下用户信息
    this.updateLoginDate = function (info, response){
        this.isLogin = true
        this.status = response.status
        this.token = response.token
        this.session = response.session
        this.userId = response.userInfo.userId
        this.userName = response.userInfo.userName
        this.userImg = response.userInfo.userImg

        this.lastLoginTime = new Date().getTime()
        localStorage.setItem("lastLoginTime", this.lastLoginTime)

        this.identifier = info.identifier
        localStorage.setItem("identifier", this.identifier)

        this.passwordMd5 = info.pwd
        localStorage.setItem("passwordMd5", this.passwordMd5)

        //console.log("login succ time: "+this.lastLoginTime)
    }

    //首次打开页面尝试自动登录
    this.tryLogin = function (that){
        if(this.lastLoginTime!=null
            &&this.identifier!=null
            &&this.passwordMd5!=null){
            let curTime = new Date().getTime()
            // console.log("login succ time: "+this.lastLoginTime)
            // console.log("cur time: "+curTime)
            // console.log("det time: ", curTime-this.lastLoginTime)
            if ((curTime-this.lastLoginTime)<evnConfig.appAPIConfig.LOGIN_CONTINUE_TIME()){
                //console.log("tryLogin 尝试登录")
                let info = {
                    userType: 1,
                    identifier: this.identifier,
                    pwd: this.passwordMd5
                }
                login.userLogin(info).then(res=>{
                    console.log(res)
                    this.updateLoginDate(info, res)
                    that.$bus.emit("onLoginSuccess", "onLoginSuccess ")
                }).catch(err=>{
                    console.log(err)
                })
            }else{
                //console.log("tryLogin 距上次登录超过2小时了")
            }
        }else{
            //console.log("tryLogin 本浏览器从没有登录过")
        }
    }

    this.exitLogin = function (){
        this.isLogin = false //是否已经登录
        this.lastLoginTime = null //上次登录时间截
        this.token = 'no' //登录token (string)
        this.session = null //持久化登录标志 (string)
        this.status = 1  //用户状态(1.正常 2.封禁 3.注销 4.封禁主页)    (unsignedTinyInteger)
        this.userId = null
        this.userName = null
        this.userImg = null

        this.identifier = null //唯一标识 手机号  (string)
        this.passwordMd5 = null //密码MD5  (string)

        localStorage.removeItem("lastLoginTime")
        localStorage.removeItem("identifier")
        localStorage.removeItem("passwordMd5")
    }
}
