import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-80007a78"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "CUploadImg"
};
const _hoisted_2 = {
  id: "labelr",
  class: "pop_file",
  for: "my_pop_video"
};
const _hoisted_3 = {
  class: "uploadView"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  key: 1,
  class: "shotImg_no_sel",
  ref: "shotImg_no_sel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("label", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$data.imgSrc !== '' ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    class: "shotImg",
    ref: "shotImg",
    src: $data.imgSrc,
    alt: ""
  }, null, 8, _hoisted_4)) : (_openBlock(), _createElementBlock("div", _hoisted_5, null, 512))]), _createElementVNode("input", {
    style: {
      "display": "none"
    },
    id: "my_pop_video",
    type: "file",
    accept: "image/*",
    onChange: _cache[0] || (_cache[0] = $event => $options.inputFile($event)),
    name: "fileTrans",
    ref: "my_pop_video",
    value: ""
  }, null, 544)])]);
}