import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2eda325a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "CCommentItem clear-fix"
};
const _hoisted_2 = {
  class: "img"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "box"
};
const _hoisted_5 = {
  class: "name"
};
const _hoisted_6 = {
  class: "time"
};
const _hoisted_7 = {
  class: "context"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
    style: {
      "width": "100%",
      "height": "100%",
      "border-radius": "50%"
    },
    src: $options.img,
    alt: ""
  }, null, 8, _hoisted_3)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString($options.userName), 1), _createElementVNode("div", _hoisted_6, _toDisplayString($options.time), 1), _createElementVNode("div", _hoisted_7, _toDisplayString($options.content), 1)])]);
}