import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a66d3618"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "jCtx4_2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.context, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index
    }, _toDisplayString(item), 1);
  }), 128))]);
}