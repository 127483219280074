import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-16296fd5"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
const _hoisted_2 = {
  class: "font",
  style: {
    "font-weight": "bolder"
  }
};
const _hoisted_3 = {
  class: "font"
};
const _hoisted_4 = {
  class: "font"
};
const _hoisted_5 = {
  class: "font"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "item",
    onClick: _cache[0] || (_cache[0] = (...args) => _ctx.onClick && _ctx.onClick(...args))
  }, [_createElementVNode("img", {
    class: "img",
    src: _ctx.img,
    alt: ""
  }, null, 8, _hoisted_1), _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.school), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.author), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.time), 1)]);
}