export default {
  name: "CCommentItem",
  props: {
    info: {
      type: Object
    }
  },
  computed: {
    content() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.info.hasOwnProperty('content')) {
        return this.info.content;
      } else {
        return '';
      }
    },
    img() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.info.hasOwnProperty('userImg')) {
        if (this.info.userImg != "") {
          return this.info.userImg;
        }
      }
      return require("../../assets/defaultUser.png");
    },
    userName() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.info.hasOwnProperty('userName')) {
        return this.info.userName;
      } else {
        return '';
      }
    },
    time() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.info.hasOwnProperty('created_at')) {
        // return  utils.date("Y年m月d日",this.daily.time/1000)
        return this.info.created_at;
      } else {
        return '';
      }
    }
  }
};