import mixDailyInfo from "@/js/mixins/mixDailyInfo";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "CR01",
  mixins: [mixDailyInfo]
  /*
  computed:{
    daily(){
      // eslint-disable-next-line no-prototype-builtins
      if(this.info.hasOwnProperty('daily')){
        return this.info.daily
      }else{
        return {}
      }
    },
    title(){
      // eslint-disable-next-line no-prototype-builtins
      if(this.daily.hasOwnProperty('title')){
        return this.daily.title
      }else{
        return ''
      }
    },
    img(){
      // eslint-disable-next-line no-prototype-builtins
      if(this.daily.hasOwnProperty('contentImage')){
        let obj = JSON.parse(this.daily.contentImage)
        return obj[0]
      }else{
        return ''
      }
    },
    author(){
      // eslint-disable-next-line no-prototype-builtins
      if(this.daily.hasOwnProperty('author')){
        return this.daily.author
      }else{
        return ''
      }
    },
    time(){
      // eslint-disable-next-line no-prototype-builtins
      if(this.daily.hasOwnProperty('time')){
        return  utils.date("Y年m月d日",this.daily.time/1000)
      }else{
        return ''
      }
    },
    keywords(){
      // eslint-disable-next-line no-prototype-builtins
      if(this.daily.hasOwnProperty('keywords')){
        if(this.daily.keywords.length==0){
          return []
        }
        let ar = this.daily.keywords.split("|")
        return ar
      }else{
        return []
      }
    }
  },
  props:{
    info:{
      type: Object,
    }
  },
  methods:{
    onClick(){
      console.log("click")
      // eslint-disable-next-line no-prototype-builtins
      if(this.info.hasOwnProperty('daily')){
        route.gotoPage(this, this.info)
      }
    }
  }
   */
};