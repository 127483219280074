import { route } from "@/js/route";
import { system } from "@/js/system";
import CDlgQrcode from "@/components/CDlgQrcode";
import CDlgWxShare from "@/components/CDlgWxShare";
export default {
  name: "CBChat",
  created() {
    // console.log("==================================屏幕宽度：")
    // console.log(window.innerWidth)
    this.autosize.maxHeight = window.innerWidth / 6;
    // console.log(this.autosize.maxHeight)
  },

  components: {
    CDlgQrcode,
    CDlgWxShare
  },
  props: {
    isCollection: {
      type: Boolean,
      default: false
    },
    isFromHome: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getIsCollection() {
      return this.isCollection;
    }
  },
  data() {
    return {
      txtValue: '',
      txtValueLast: '',
      autosize: {
        maxHeight: 100
      },
      isFouce: false,
      placeholder: "我来说两句",
      altUserId: 0,
      altUserName: '',
      isBottomFill: false
    };
  },
  mounted() {
    this.isBottomFill = system.isHMobile();
    // console.log("--hh--------------------"+this.$refs.lwBottomChatBox.offsetHeight)
    // let obj = this.$refs.lwBottomChatBox
    // let h = window.getComputedStyle(obj).height;  //通过这个方法，获取宽高
    // console.log("--ww--------------------"+h)
  },

  methods: {
    onClickLeft() {
      // console.log('on click back ', history.length)
      // history.back()
      route.gotoBack(this, this.isFromHome);
    },
    getheight() {
      // console.log("-getheight-hh--------------------"+this.$refs.lwBottomChatBox.offsetHeight)
      // let obj = this.$refs.lwBottomChatBox
      // let h = window.getComputedStyle(obj).height;  //通过这个方法，获取宽高
      // console.log("-getheight-ww--------------------"+h)
    },
    onFocus() {
      this.isFouce = true;
      this.txtValue = this.txtValueLast;
      this.$emit("onFocus");
    },
    onBlur() {
      this.placeholder = "我来说两句";
      setTimeout(() => {
        console.log('onBlur');
        this.isFouce = false;
        this.altUserId = 0;
        this.altUserName = '';
        this.txtValueLast = this.txtValue;
        this.txtValue = '';
      }, 150);
    },
    onCommentDaily() {
      console.log('发送数据');
      if (this.txtValue == '') {
        return;
      }
      let obj = {
        'content': this.txtValue
      };
      this.$emit("commentDaily", obj);
      this.txtValue = '';
      this.txtValueLast = '';
    },
    onCollectionDaily() {
      console.log("onCollectionDaily");
      this.$emit("collectionDaily");
    },
    onShareQrcode() {
      console.log(this.$route);
      system.isHMobile();
      if (system.isWeChat()) {
        this.$refs.CDlgWxShare.show = true;
      } else {
        this.$refs.CDlgQrcode.show = true;
      }
    }
  }
};