export default {
  name: "adbCtx9",
  props: {
    titleCn1: {
      type: String,
      default: '澳洲'
    },
    titleCn2: {
      type: String,
      default: '申请'
    },
    titleEn: {
      type: String,
      default: 'Austranan application'
    }
  }
};