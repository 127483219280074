import CR01 from "@/components/CR01";
import CLine from "@/components/CLine";
import PBottom from "@/components/PBottom";
import mixGetDetailByClassify from "@/js/mixins/mixGetDetailByClassify";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "PLuntan",
  components: {
    CLine,
    CR01,
    PBottom
  },
  mixins: [mixGetDetailByClassify],
  data() {
    return {
      classify: 5
    };
  }
};