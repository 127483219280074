import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2cf6a56a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "page-margin"
};
const _hoisted_2 = {
  class: "video"
};
const _hoisted_3 = {
  class: "page-margin"
};
const _hoisted_4 = {
  class: "context paddingLR"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CWZTitle = _resolveComponent("CWZTitle");
  const _component_CMediaPlayer = _resolveComponent("CMediaPlayer");
  const _component_CComment = _resolveComponent("CComment");
  const _component_CBChat = _resolveComponent("CBChat");
  const _component_PBottom = _resolveComponent("PBottom");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_CWZTitle, {
    info: $data.info
  }, null, 8, ["info"])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_CMediaPlayer, {
    class: "mediaPlayer",
    ref: "myVideo2",
    isLogo: true,
    isLookBack: false,
    src: $options.sourceUrl,
    poster: $options.posterUrl
  }, null, 8, ["src", "poster"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString($options.contentText), 1), _createVNode(_component_CComment, {
    ref: "CComment",
    info: $data.info
  }, null, 8, ["info"]), _createVNode(_component_CBChat, {
    isCollection: $data.isCollection,
    isFromHome: $data.isFromHome,
    onCollectionDaily: $options.onCollectionDaily,
    onCommentDaily: $options.onCommentDaily
  }, null, 8, ["isCollection", "isFromHome", "onCollectionDaily", "onCommentDaily"]), _createVNode(_component_PBottom)])], 64);
}