import FCom from "@/components/FCom";
import { route } from "@/js/route";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "F01",
  components: {
    FCom
  },
  props: {
    title: {
      type: String,
      required: true
    },
    detail: {
      type: Array,
      required: true
    }
  },
  methods: {
    getImgUrl(item) {
      // console.log("imgUrl:",item)
      let imgs = JSON.parse(item.daily.contentImage);
      if (imgs.length > 0) {
        return imgs[0];
      } else {
        return '';
      }
    },
    onClick(item) {
      route.gotoPage(this, item);
    }
  }
};