import { showFailToast } from "vant";
import clientOSS from "@/js/clientOSS";
export default {
  name: "CUploadImg",
  props: {
    proImgSrc: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      imgSrc: this.proImgSrc,
      file: null,
      lastFile: null
    };
  },
  methods: {
    reset() {
      this.imgSrc = this.proImgSrc;
      this.file = null;
      this.lastFile = null;
    },
    uploadFileToOss() {
      let p = new Promise((resolve, reject) => {
        if (this.file != null && (this.lastFile == null || this.file.size != this.lastFile.size || this.file.name != this.lastFile.name || this.file.type != this.lastFile.type)) {
          clientOSS.putFile('userHead', this.file).then(res => {
            resolve({
              state: 0,
              url: clientOSS.getOssPath(res.name)
            });
            this.lastFile = this.file;
          }).catch(() => {
            reject({
              state: -1
            });
          });
        } else {
          resolve({
            state: 1
          });
        }
      });
      return p;
    },
    inputFile(ev) {
      var files = ev.target.files;
      if (files.length == 0) {
        return;
      }
      //判断视频大小是否大于100Mb
      if (files[0].size > 3 * 1024 * 1024) {
        showFailToast('图片超过3MB');
        return;
      }
      let videoSrcUrl = getFileURL(files[0]); //把当前的 files[0] 传进去
      if (videoSrcUrl) {
        this.imgSrc = videoSrcUrl;
        this.file = files[0];
      }
      function getFileURL(file) {
        var getUrl = null;
        if (window.createObjectURL != undefined) {
          // basic
          getUrl = window.createObjectURL(file);
        } else if (window.URL != undefined) {
          // mozilla(firefox)
          getUrl = window.URL.createObjectURL(file);
        } else if (window.webkitURL != undefined) {
          // webkit or chrome
          getUrl = window.webkitURL.createObjectURL(file);
        }
        return getUrl;
      }
    }
  }
};