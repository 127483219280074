import { ref } from "vue";
import CLoginTitle from "@/components/login/CLoginTitle";
import CUserFooter from "@/components/login/CUserFooter";
import CUserContext from "@/components/login/CUserContext";
import { showConfirmDialog, showToast } from "vant";
import { utils } from "@/js/utils";
export default {
  name: "CUser",
  data() {
    return {
      userInfo: null
    };
  },
  created() {
    this.userInfo = this.$userInfo;
  },
  components: {
    CLoginTitle,
    CUserFooter,
    CUserContext
  },
  watch: {
    show() {
      this.$nextTick(() => {
        this.$refs.CUserContext.reset();
      });
    }
  },
  setup() {
    const show = ref(false);
    return {
      show
    };
  },
  methods: {
    onExitLogin() {
      this.$refs.CUserContext.reset();
      console.log("onExitLogin");
      this.$emit("exitLogin");
    },
    onModify() {
      let that = this;
      let info = this.$refs.CUserContext.mInfo;
      //校检数据是否有效
      if (utils.checkModbile(info.identifier)) {
        showConfirmDialog({
          message: '是否保存个人信息？'
        }).then(() => {
          //上传图片
          this.$refs.CUserContext.uploadFileToOss().then(res => {
            console.log("oss上传头像：", res);
            if (res.state === 0) {
              that.userInfo.userImg = res.url;
              info.userImg = res.url;
            }
            this.$emit("modify", {
              info: info
            });
          }).cache(err => {
            console.log("oss上传头像失败：", err);
            showToast('头像上传失败');
          });
          // on confirm
        }).catch(() => {
          // on cancel
          console.log("取消：");
          showToast('保存信息失败');
        });
      } else {
        showToast('请填写正确的联系电话');
      }
    },
    onClose() {
      console.log("reset ********************");
      this.$refs.CUserContext.reset();
      this.show = false;
    }
  }
};