
import {showToast} from "vant";
import {bgDaily} from "@/js/bgDaily";

let mixGetDetailByClassify = {
    data(){
        return {
            count: 0,
            loading: false,
            finished: false,
            refreshing: false,

            refreshTime: 0,
            loadTime: 0,

            dailies: []
        }
    },
    created() {
        // console.log("========"+this.$options.name)
        this.loading = true
        this.getDetailLoad()
    },
    methods:{
        onLoad(){
            setTimeout(() => {
                if (this.refreshing) {
                    this.refreshing = false;
                }
                this.getDetailLoad()
            }, 300);
        },
        onRefresh(){
            setTimeout(() => {
                this.getDetailRefresh()
            }, 300);
        },
        getDetailRefresh(){
            if(this.dailies.length>0){
                this.refreshTime = this.dailies[0].daily.time
            }
            bgDaily.getDailiesByClassify({
                classify: this.classify,
                refreshTime: this.refreshTime,
                topCount: 0,
                topIds: []
            }).then(res=>{
                let dailies = res.dailies.reverse()
                for (let index in dailies){
                    // console.log(dailies[index])
                    // console.log(dailies[index].daily.time)
                    this.dailies.unshift(dailies[index])
                }
                // console.log("===========================")
                // console.log(this.dailies)
                showToast('刷新成功');
            }).finally(()=>{
                this.refreshing = false;
            })
        },
        getDetailLoad(){
            bgDaily.getDailiesByClassify({
                classify: this.classify,
                loadTime: this.loadTime,
                topCount: 0,
                topIds: []
            }).then(res=>{
                // console.log(res)
                let lastTime = this.loadTime
                for (let index in res.dailies){
                    // console.log(res.dailies[index])
                    if(this.loadTime != res.dailies[index].daily.time){
                        this.dailies.push(res.dailies[index])
                        this.loadTime = res.dailies[index].daily.time
                    }
                    // console.log(this.loadTime)
                }
                if(lastTime === this.loadTime){
                    this.finished = true;
                }
            }).catch(()=>{
                console.log("catch==================================")
            }).finally(()=>{
                this.count++
                this.loading = false;
                // console.log("finally======================"+this.count+"  name:",this.$options.name)
            })
        }
    }

}

export default mixGetDetailByClassify