import videojs from 'video.js'

var Component = videojs.getComponent('Component');

class TitleBarContain extends Component {
    constructor(player, options = {}) {
        super(player, options);
    }
    createEl() {
        return videojs.dom.createEl('div', {
            className: 'vjs-title-bar'
        });
    }
    setTitle(text){
        this.children_[1].updateTextContent(text)
    }
}

function TitleBarLeftBtn(player) {
    var Button = videojs.getComponent('Button');
    var button = new Button(player, {
        controlText: 'Live',
        className: 'vjs-title-bar-left-btn',
        clickHandler: function(event) {
            videojs.log('Clicked: isFullScreen: ---'+player.isFullscreen());
            if(player.isFullscreen()){
                player.exitFullscreen()
            }
        }
    });
    return button
}

class TitleBarText extends Component {
    constructor(player, options = {}) {
        super(player, options);
        if (options.text) {
            this.updateTextContent(options.text);
        }
    }
    createEl() {
        return videojs.dom.createEl('div', {
            className: 'vjs-title-bar-text'
        });
    }
    updateTextContent(text) {
        if (typeof text !== 'string') {
            text = 'Title Unknown';
        }
        videojs.dom.emptyEl(this.el());
        videojs.dom.appendContent(this.el(), text);
    }
}

export function compTitleBar(player, title ="测试标题", childName='BigPlayButton'){
// //全屏时，显示标题栏
    let titleBarContain = new TitleBarContain(player);

    var button = TitleBarLeftBtn(player);
    titleBarContain.addChild(button)

    let titleBarText = new TitleBarText(player, {text: title});
    titleBarContain.addChild(titleBarText)

    // titleBarContain.setTitle("我在测*****试")

    return titleBarContain
    // let bigPlayIndex = player.children().indexOf(player.getChild(childName));
    // player.addChild(titleBarContain, {}, bigPlayIndex)
}
