import { route } from "@/js/route";
export default {
  name: "CBackNav",
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    onClickLeft() {
      console.log('on click back ', history.length);
      // history.back()
      route.gotoBack(this);
    }
  }
};