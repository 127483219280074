import videojs from 'video.js'

var Component = videojs.getComponent('Button');

export class CompLiveText extends Component {
    constructor(player, options = {}) {
        super(player, options);
        this.updateTextContent()
    }
    createEl() {
        return videojs.dom.createEl('div', {
            controlText: 'Live',
            className: 'vjs-time-control',
        });
    }
    updateTextContent() {
        videojs.dom.emptyEl(this.el());
        videojs.dom.appendContent(this.el(), "Live");
    }
}
