//对象语法<!--           :class="{active_index: item.id === currentIndex}"   :class="['z'+item.id, {active_index: item.id === currentIndex}]"-->
export default {
  name: "tabCtrl",
  props: {
    titles: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      currentIndex: 0,
      offsetX: 0
    };
  },
  mounted() {},
  methods: {
    itemClick(titleID, index, max) {
      // console.log(this.$refs)
      // console.log("cur="+index)
      // console.log("max="+max)

      let x = this.getXOffset(index, max);
      this.scrollTo(this.$refs.box, x, 0);
      if (this.currentIndex == titleID) return;
      this.currentIndex = titleID;
      this.$emit('tabClick', titleID);
    },
    setIndex(id) {
      this.currentIndex = id;
    },
    scrollTo(element, x, y) {
      if (element.scrollTo) element.scrollTo(x, y);
    },
    getELLeft(id) {
      const refs = this.$refs[`item+${id}`];
      if (refs && refs.length > 0) {
        return refs[0].offsetLeft;
      }
      return 0;
    },
    getELWidth(id) {
      const refs = this.$refs[`item+${id}`];
      if (refs && refs.length > 0) {
        return refs[0].offsetWidth;
      }
      return 0;
    },
    getXOffset(cur, max) {
      let boxScrollCurLeft;
      let boxScrollMaxLeft;
      let boxWidth;
      if (this.$refs.box) {
        boxScrollCurLeft = this.$refs.box.scrollLeft;
        boxWidth = this.$refs.box.offsetWidth;
        if (this.$refs.box.scrollWidth > boxWidth) {
          boxScrollMaxLeft = this.$refs.box.scrollWidth - boxWidth;
        } else {
          boxScrollMaxLeft = 0;
        }

        // console.log("box curL="+boxScrollCurLeft+" maxL="+boxScrollMaxLeft+" w="+boxWidth)
      } else {
        return 0;
      }
      if (cur <= 1) return 0;
      let clickLeft = this.getELLeft(cur);
      let clickPreW = this.getELWidth(cur - 1);
      if (clickLeft == 0 || clickPreW == 0) {
        // console.log("element err***")
        return 0;
      }
      if (clickLeft - clickPreW < boxScrollCurLeft) {
        // console.log("l1")
        return clickLeft - clickPreW;
      } else if (cur == max - 1) {
        // console.log("max")
        return boxScrollMaxLeft;
      } else {
        let clickNextLeft = this.getELLeft(cur + 1);
        let clickNextW = this.getELWidth(cur + 1);
        if (clickNextLeft == 0 || clickNextW == 0) {
          // console.log("element err***2")
          return 0;
        }
        if (clickNextLeft + clickNextW > boxScrollCurLeft + boxWidth) {
          // console.log("r1")
          return clickNextLeft + clickNextW - boxWidth;
        } else {
          // console.log("no done")
          return boxScrollCurLeft;
        }
      }
      return 0;
    }
  }
};