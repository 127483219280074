export default {
  name: "adjCtx4_1",
  props: {
    context: {
      type: String,
      default: 'BC省'
    },
    color: {
      type: Number,
      default: 0
    }
  }
};