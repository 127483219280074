import CR04NameItem from "@/components/CR04NameItem";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "CR04",
  components: {
    CR04NameItem
  },
  props: {
    dailies: {
      type: Object,
      required: true
    }
  }
};