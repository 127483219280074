import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-41191577"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "CImgIdentifyCodeContext"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "inputCode"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_dialog = _resolveComponent("van-dialog");
  return _openBlock(), _createBlock(_component_van_dialog, {
    show: $props.show,
    "show-cancel-button": "",
    title: "请填写图片中的内容",
    beforeClose: $options.beforeClose,
    width: "var(--login-width)"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
      class: "refresh",
      onClick: _cache[0] || (_cache[0] = (...args) => $options.onRefresh && $options.onRefresh(...args))
    }, [_createVNode(_component_van_icon, {
      name: "replay",
      size: "1.2rem",
      color: "var(--bg-color)"
    })]), _createElementVNode("img", {
      class: "img",
      src: $props.imgBase
    }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createVNode(_component_van_field, {
      modelValue: $data.code,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.code = $event),
      placeholder: "填写内容",
      border: "",
      maxlength: "4"
    }, null, 8, ["modelValue"])])])]),
    _: 1
  }, 8, ["show", "beforeClose"]);
}