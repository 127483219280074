import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5cb8f288"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tab-control",
  ref: "box"
};
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.titles, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["ul", ['z' + index, {
        active_index: item.id == $data.currentIndex
      }]]),
      ref_for: true,
      ref: `item+${index}`
    }, [_createElementVNode("div", {
      onClick: $event => $options.itemClick(item.id, index, $props.titles.length),
      class: _normalizeClass({
        activeLeft: item.id == $data.currentIndex && index == 0,
        activeMid: item.id == $data.currentIndex && index != 0 && index != $props.titles.length - 1,
        activeRight: item.id == $data.currentIndex && index != 0 && index == $props.titles.length - 1,
        active_no_sel: item.id != $data.currentIndex,
        active_sel_final: item.id == $data.currentIndex && $data.currentIndex == 5,
        leftBottom: index == 0,
        midBottom: index != 0 && index != $props.titles.length - 1,
        rightBottom: index != 0 && index == $props.titles.length - 1
      })
    }, [_createElementVNode("span", null, _toDisplayString(item.name), 1)], 10, _hoisted_2)], 2);
  }), 256))], 512);
}