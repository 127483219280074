import { ref } from "vue";
import CQrcode from "@/components/CQrcode";
export default {
  name: "CDlgQrcode",
  components: {
    CQrcode
  },
  setup() {
    const show = ref(false);
    return {
      show
    };
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onBeforeClose() {
      this.onClose();
    }
  }
};