export default {
  name: "adbCtx1",
  props: {
    title: {
      type: String,
      default: ''
    },
    context: {
      type: String,
      default: ''
    }
  }
};