import {httpBg,getToken} from "@/js/http/httpBg";


function http(method, bizContent={}) {
    return httpBg({
        url: '/toodo/tdk/daily?token=' + getToken(),
        data: {
            method,
            bizContent
        }
    })
}

// 获取分类
function getClassify(bizContent={}) {
    let p = new Promise((resolve,reject)=>{
        // console.log("bizContent:",bizContent)
        http("/getClassify", bizContent).then(res=>{
            // console.log(res)
            resolve(res)
        }).catch(err=>{
            console.log(err)
            reject({code: err.code, msg:err.msg})
        }).finally(()=>{})
    })
    return p;
}

// 获取推荐动态
function getRecommendDailies(bizContent={}) {
    let p = new Promise((resolve,reject)=>{
        // console.log("bizContent:",bizContent)
        http("/getRecommendDailies", bizContent).then(res=>{
            // console.log(res)
            resolve(res)
        }).catch(err=>{
            console.log(err)
            reject({code: err.code, msg:err.msg})
        }).finally(()=>{})
    })
    return p;
}

//获取分类下动态
function getDailiesByClassify(bizContent={}) {
    let p = new Promise((resolve,reject)=>{
        // console.log("bizContent:",bizContent)
        http("/getDailiesByClassify", bizContent).then(res=>{
            // console.log(res)
            resolve(res)
        }).catch(err=>{
            console.log(err)
            reject({code: err.code, msg:err.msg})
        }).finally(()=>{})
    })
    return p;
}

// 获取单条动态
function getDaily(bizContent={}) {
    let p = new Promise((resolve,reject)=>{
        // console.log("bizContent:",bizContent)
        http("/getDaily", bizContent).then(res=>{
            // console.log(res)
            resolve(res)
        }).catch(err=>{
            console.log(err)
            reject({code: err.code, msg:err.msg})
        }).finally(()=>{})
    })
    return p;
}

// 获取动态评论
// id, //动态编号   (bigInteger)
// type,     //动态类型:0 文章 1 视频    (smallInteger)
// userId,     //动态发布用户编号  (bigInteger)
// order,   //排序   0:评论数 1:时间     (Integer)
// start,   //偏移0       (Integer) (可选)
// limit,   //条数10     (Integer) (可选)
// commentId,  //评论编号  (bigInteger) (可选)
function getDailyComment(bizContent={}) {
    let p = new Promise((resolve,reject)=>{
        // console.log("bizContent:",bizContent)
        http("/getDailyComment", bizContent).then(res=>{
            // console.log(res)
            resolve(res)
        }).catch(err=>{
            console.log(err)
            reject({code: err.code, msg:err.msg})
        }).finally(()=>{})
    })
    return p;
}

// 获取动态关注收藏状态
// id,       //  (bigIncrements)
// type,     //动态类型:0 文章 1 视频    (smallInteger)
function getDailyStatus(bizContent={}) {
    let p = new Promise((resolve,reject)=>{
        // console.log("bizContent:",bizContent)
        http("/getDailyStatus", bizContent).then(res=>{
            // console.log(res)
            resolve(res)
        }).catch(err=>{
            console.log(err)
            reject({code: err.code, msg:err.msg})
        }).finally(()=>{})
    })
    return p;
}

// 收藏动态
// id,       //  (bigIncrements)
// type,     //动态类型:0 文章 1 视频    (smallInteger)
function collectionDaily(bizContent={}) {
    let p = new Promise((resolve,reject)=>{
        // console.log("bizContent:",bizContent)
        http("/collectionDaily", bizContent).then(res=>{
            // console.log(res)
            resolve(res)
        }).catch(err=>{
            console.log(err)
            reject({code: err.code, msg:err.msg})
        }).finally(()=>{})
    })
    return p;
}

// 评论动态
// id, //动态编号   (bigInteger)
// content,    //用户评论，礼物类型评论 0代表鲜花  (string)
// ownerId,    //所属评论编号    (可选)    (bigInteger)
// commentId,  //评论编号  (可选)    (bigInteger)
function commentDaily(bizContent={}) {
    let p = new Promise((resolve,reject)=>{
        // console.log("bizContent:",bizContent)
        http("/commentDaily", bizContent).then(res=>{
            // console.log(res)
            resolve(res)
        }).catch(err=>{
            console.log(err)
            reject({code: err.code, msg:err.msg})
        }).finally(()=>{})
    })
    return p;
}

export const bgDaily = {
    getClassify,
    getRecommendDailies,
    getDailiesByClassify,
    getDaily,
    getDailyComment,
    getDailyStatus,
    collectionDaily,
    commentDaily
}