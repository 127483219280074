export default {
  name: "adbCtx2",
  props: {
    mounth: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    context: {
      type: String,
      default: ''
    }
  }
};