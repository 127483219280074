import videojs from 'video.js'

var Component = videojs.getComponent('Component');

export class CompLookBack extends Component {
    constructor(player, options = {}) {
        super(player, options);
        this.updateTextContent()
    }
    createEl() {
        return videojs.dom.createEl('div', {
            className: 'vjs-look-back'
        });
    }
    updateTextContent() {
        videojs.dom.emptyEl(this.el());
        videojs.dom.appendContent(this.el(), "回顾");
    }
}

// export function videoCreateLookBack(player, childName='BigPlayButton'){
//     let lookBack = new CompLookBack(player);
//     let bigPlayIndex = player.children().indexOf(player.getChild(childName));
//     player.addChild(lookBack, {}, bigPlayIndex)
// }
