import QRCode from 'qrcodejs2';
import { evnConfig } from "@/js/evnConfig";
export default {
  name: "CQrcode",
  data() {
    return {};
  },
  mounted() {
    console.log("mounted%%%%%%%%%%%%%%%%%%%%%:", evnConfig.makeShareUrl(this.$route.fullPath));
    this.creatQrcode(evnConfig.makeShareUrl(this.$route.fullPath));
  },
  activated() {
    console.log("activated%%%%%%%%%%%%%%%%%%%%%:", evnConfig.makeShareUrl() + this.$route.fullPath);
  },
  methods: {
    // 生成二维码
    creatQrcode(url) {
      new QRCode(this.$refs.qrcode, {
        text: encodeURI(url),
        width: 200,
        height: 200
      });
    }
  }
};