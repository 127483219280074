import axios from 'axios'
import md5 from "js-md5"
import {utils} from "../utils"
import {evnConfig} from "../evnConfig";
import {eventGetToken, eventLogin, eventRefreshToken, eventSessionGetToken} from "@/js/eventBus";


//当返回这两个错误码时需要重新获取token
//验证授权失败 被强制下线
const ACCOUNT_NO_LOGIN = 10005;
//登录过期
const LOGOUT_EXPIRE = 11010;

function ServerConfig(){
  this.timeSting = '2000-01-01 00:00:00'
}
const serverInfo = new ServerConfig() //'2000-01-01 00:00:00'

function RequestBody(method, bizContent) {
  this.appId = evnConfig.appAPIConfig.APP_ID();
  this.method = method;
  this.format = 'JSON';
  this.charset = 'utf-8';
  this.signCode = '';
  this.timestamp = utils.toDate();
  this.version = '1.0';
  this.appAuthToken = '';
  this.bizContent = bizContent;
  this.encrypt = function () {
    let keys = Object.keys(this).sort();
    let signCode = '';
    keys.forEach(key => {
      if (key !== 'encrypt') signCode += this[key]
    });
    signCode += evnConfig.appAPIConfig.SECRET();
    this.signCode = md5(signCode);
  }
}

/**
 * 响应体
 * @constructor
 */
function ResponseBody() {
  this.code = "";
  this.subCode = "";
  this.msg = "";
  this.subMsg = "";
  this.timestamp = "";
  this.sign = "";
  this.bizContent = "";
  this.token = "";
  this.check = function () {
    if (this.code !== 0) {
      if (this.code === ACCOUNT_NO_LOGIN ) {
        //发送一条总线命令，让其通知想知道的组件
        eventLogin()
      }else if(this.code === LOGOUT_EXPIRE){
        eventSessionGetToken()
      }
      throw {code: this.code, msg: this.msg};
    }
    let keys = Object.keys(this).sort();
    let signCode = '';
    keys.forEach(key => {
      if (
          key !== 'fillBody' &&
          key !== 'getData' &&
          key !== 'check' &&
          key !== 'sign'
      ) signCode += this[key]
    });
    signCode += evnConfig.appAPIConfig.SECRET();
    if (md5(signCode) !== this.sign) {
      throw {code: -1, msg: '签名错误'};
    }

    // 判断token是否需要更新
    if (this.token && eventGetToken() !== this.token) {
      eventRefreshToken(this.token)
    }
  };
  this.getData = function () {
    return JSON.parse(this.bizContent)
  }
}

// `transformRequest` 允许在向服务器发送前，修改请求数据
function actTransformRequest(data) {
  if (typeof (data.bizContent) === 'object') data.bizContent = JSON.stringify(data.bizContent);
  const requestBody = new RequestBody(data.method, data.bizContent);
  requestBody.encrypt();
  return JSON.stringify(requestBody)
}

// `transformResponse` 在传递给 then/catch 前，允许修改响应数据
function actTransformResponse(data) {
  // 当接口返回新的token时需要将token回传给app
  const responseBody = new ResponseBody();
  utils.fillData(JSON.parse(data), responseBody);
  responseBody.check();
  // console.log('返回的系统时间')
  // console.log(responseBody.timestamp)
  serverInfo.timeSting = responseBody.timestamp
  // alert(JSON.stringify(responseBody))
  return responseBody.getData()
}

export function httpBg(config) {
  const instance = axios.create({
    baseURL: evnConfig.getAppAPIBaseUrl(),
    method: "POST",
    timeout: 15000,
    headers: {'Content-Type': 'application/json'},
    transformRequest: actTransformRequest,
    transformResponse: actTransformResponse
  })

  //请求拦截
  instance.interceptors.request.use(config=>{
    return config
  }, err=>{
    throw err;
  })
  //响应拦截
  instance.interceptors.response.use(res=>{
      return res.data
    }, err=>{
      throw err;
    }
  )
  return instance(config)
}

export function getToken(){
  return eventGetToken()
}

