import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7d1d0af8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  style: {
    "width": "2rem"
  }
};
const _hoisted_2 = {
  key: 1,
  style: {
    "width": "2rem"
  }
};
const _hoisted_3 = {
  key: 4,
  class: "forgetPwd"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_count_down = _resolveComponent("van-count-down");
  const _component_van_button = _resolveComponent("van-button");
  const _component_van_cell_group = _resolveComponent("van-cell-group");
  const _component_CImgIdentifyCode = _resolveComponent("CImgIdentifyCode");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_cell_group, {
    inset: ""
  }, {
    default: _withCtx(() => [$data.table[$props.mode - 1][0] ? (_openBlock(), _createBlock(_component_van_field, {
      key: 0,
      modelValue: $setup.username,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.username = $event),
      name: "手机号",
      label: "手机号",
      placeholder: "手机号",
      "error-message": $data.usernameErr
    }, null, 8, ["modelValue", "error-message"])) : _createCommentVNode("", true), $data.table[$props.mode - 1][1] ? (_openBlock(), _createBlock(_component_van_field, {
      key: 1,
      modelValue: $setup.sms,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.sms = $event),
      center: "",
      clearable: "",
      label: "短信验证码",
      placeholder: "请输入短信验证码",
      "error-message": $data.smsErr
    }, {
      button: _withCtx(() => [_createVNode(_component_van_button, {
        size: "small",
        type: "primary",
        disabled: $data.bSmsBtnState,
        onClick: $options.onSendSms
      }, {
        default: _withCtx(() => [!$data.bSmsBtnState ? (_openBlock(), _createElementBlock("div", _hoisted_1, "发送验证码")) : (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_van_count_down, {
          ref: "countDown",
          time: 120000,
          "auto-start": false,
          format: "ss秒",
          onFinish: $options.onFinish
        }, null, 8, ["onFinish"])]))]),
        _: 1
      }, 8, ["disabled", "onClick"])]),
      _: 1
    }, 8, ["modelValue", "error-message"])) : _createCommentVNode("", true), $data.table[$props.mode - 1][2] ? (_openBlock(), _createBlock(_component_van_field, {
      key: 2,
      modelValue: $setup.password,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $setup.password = $event),
      type: "password",
      name: "密码",
      label: "密码",
      placeholder: "密码",
      "error-message": $data.pwdErr
    }, null, 8, ["modelValue", "error-message"])) : _createCommentVNode("", true), $data.table[$props.mode - 1][3] ? (_openBlock(), _createBlock(_component_van_field, {
      key: 3,
      modelValue: $setup.password2,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $setup.password2 = $event),
      type: "password",
      name: "确认密码",
      label: "确认密码",
      placeholder: "确认密码",
      "error-message": $data.pwdErr2
    }, null, 8, ["modelValue", "error-message"])) : _createCommentVNode("", true), $data.table[$props.mode - 1][4] || $data.table[$props.mode - 1][5] || $data.table[$props.mode - 1][6] ? (_openBlock(), _createElementBlock("div", _hoisted_3, [$data.table[$props.mode - 1][4] ? (_openBlock(), _createElementBlock("span", {
      key: 0,
      onClick: _cache[4] || (_cache[4] = $event => $options.onSwitchMode(1))
    }, "登录")) : _createCommentVNode("", true), $data.table[$props.mode - 1][5] ? (_openBlock(), _createElementBlock("span", {
      key: 1,
      onClick: _cache[5] || (_cache[5] = $event => $options.onSwitchMode(3))
    }, "忘记密码")) : _createCommentVNode("", true), $data.table[$props.mode - 1][6] ? (_openBlock(), _createElementBlock("span", {
      key: 2,
      onClick: _cache[6] || (_cache[6] = $event => $options.onSwitchMode(2))
    }, "手机号注册")) : _createCommentVNode("", true)])) : _createCommentVNode("", true)]),
    _: 1
  }), _createVNode(_component_CImgIdentifyCode, {
    show: $data.isShowImgIdentifyCode,
    imgBase: $data.imgBase,
    onImgConfirm: $options.onImgConfirm,
    onImgClose: $options.onImgClose,
    onRefresh: $options.onRefresh
  }, null, 8, ["show", "imgBase", "onImgConfirm", "onImgClose", "onRefresh"])]);
}