import CLine from "@/components/CLine";
import PBottom from "@/components/PBottom";
import mixGetDetailByClassify from "@/js/mixins/mixGetDetailByClassify";
import CR04Name from "@/components/CR04Name";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "PQianke",
  components: {
    CLine,
    CR04Name,
    PBottom
  },
  mixins: [mixGetDetailByClassify],
  data() {
    return {
      classify: 3
    };
  }
};