import { ref } from 'vue';
import PFirst from "@/page/PFirst";
import PNotify from "@/page/PNotify";
import PBaiyuan from "@/page/PBaiyuan";
import PQianke from "@/page/PQianke";
import PLuntan from "@/page/PLuntan";
import PActive from "@/page/PActive";
import UndefinePage from "@/page/UndefinePage";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "PNav",
  components: {
    UndefinePage,
    PFirst,
    PNotify,
    PBaiyuan,
    PQianke,
    PActive,
    PLuntan
  },
  setup() {
    const active = ref(0);

    // const beforeChange = (index) => {
    //   // 返回 false 表示阻止此次切换
    //   console.log("beforeChange :"+index+" active:")
    //   return true
    // };

    const onlineSrc = require("../assets/logo.png");
    const titles = [{
      title: '首页',
      to: "/home/first"
    }, {
      title: '通知',
      to: "/home/notify"
    }, {
      title: '百园',
      to: "/home/first"
    }, {
      title: '千课',
      to: "/home/first"
    }, {
      title: '活动',
      to: "/home/first"
    }, {
      title: '论坛',
      to: "/home/first"
    }];
    const images = ['https://fastly.jsdelivr.net/npm/@vant/assets/apple-1.jpeg', 'https://fastly.jsdelivr.net/npm/@vant/assets/apple-2.jpeg'];
    return {
      onlineSrc,
      titles,
      images,
      active
    };
  },
  methods: {
    beforeChange() {
      // 返回 false 表示阻止此次切换
      // console.log("beforeChange :"+index+" active:"+this.active)
      return true;
    },
    change() {
      // console.log("change :"+index+" active:"+this.active)
    }
  }
  // data(){
  //   return {
  //     onlineSrc: require("../assets/logo.png"),
  //     titles: [
  //       {title:'首页', to:"/home/first"},
  //       {title:'通知', to:"/home/notify"},
  //       {title:'百园', to:"/home/first"},
  //       {title:'千课', to:"/home/first"},
  //       {title:'活动', to:"/home/first"},
  //       {title:'论坛', to:"/home/first"}
  //       ],
  //     images:[
  //       'https://fastly.jsdelivr.net/npm/@vant/assets/apple-1.jpeg',
  //       'https://fastly.jsdelivr.net/npm/@vant/assets/apple-2.jpeg',
  //     ]
  //   }
  // },
};