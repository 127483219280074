import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-63d6265e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "background-color": "var(--adv-bg-color2)"
  }
};
const _hoisted_2 = {
  class: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_tabCtrl = _resolveComponent("tabCtrl");
  const _component_aodaoliya = _resolveComponent("aodaoliya");
  const _component_jialada = _resolveComponent("jialada");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_tabCtrl, {
    class: "tab-control",
    ref: "tabControl",
    titles: $data.titles,
    onTabClick: $options.tabClick
  }, null, 8, ["titles", "onTabClick"])]), $data.index == 0 ? (_openBlock(), _createBlock(_component_aodaoliya, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_jialada, {
    key: 1
  }))]);
}