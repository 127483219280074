import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CUserContext = _resolveComponent("CUserContext");
  const _component_CLoginTitle = _resolveComponent("CLoginTitle");
  const _component_CUserFooter = _resolveComponent("CUserFooter");
  const _component_van_dialog = _resolveComponent("van-dialog");
  return _openBlock(), _createBlock(_component_van_dialog, {
    show: $setup.show,
    width: "var(--login-width)"
  }, {
    title: _withCtx(() => [_createVNode(_component_CLoginTitle, {
      title: "个人信息",
      onClose: $options.onClose
    }, null, 8, ["onClose"])]),
    footer: _withCtx(() => [_createVNode(_component_CUserFooter, {
      title: "退出登录",
      title2: "保存修改信息",
      onSubmit: $options.onExitLogin,
      onModify: $options.onModify
    }, null, 8, ["onSubmit", "onModify"])]),
    default: _withCtx(() => [_createVNode(_component_CUserContext, {
      ref: "CUserContext"
    }, null, 512)]),
    _: 1
  }, 8, ["show"]);
}