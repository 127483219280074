export default {
  name: "CHtmlPanel",
  // https://act-web.toodo.com.cn/science-agreement/policy.html
  // https://act-web.toodo.com.cn/science-agreement/agreement.html
  // https://kepu.oss.toodo.com.cn/daily/article/2880815_2022_05_11_10_33_25.html
  props: {
    url: {
      required: true
    }
  },
  data() {
    return {
      html: ""
    };
  },
  watch: {
    url(value) {
      this.load(value);
    }
  },
  mounted() {
    this.load(this.url);
  },
  methods: {
    load(url) {
      if (url && url.length > 0) {
        let param = {
          accept: "text/html, text/plain"
        };
        this.$http.get(url, param).then(response => {
          this.html = response.data;
        }).catch(() => {
          this.html = "加载失败";
        });
      }
    }
  }
};