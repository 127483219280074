export default {
  name: "CLoginTitle",
  props: {
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    onClickRight() {
      this.$emit("close", null);
    }
  }
};