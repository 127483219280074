import videojs from 'video.js'

const Component = videojs.getComponent("Component");
export class CompDragTime extends Component {
    constructor (player, options) {
        super(player, options);

        this.myPlayer = player
        player.on("loadedmetadata", () => {
            // console.log("------------------------TouchOverlay------------------------------")
            this.totalDuration = player.duration();
            // console.log("------------------------TouchOverlay------------------------------"+this.totalDuration)
        });
        this.on("touchstart", this.handleTouchstart);
        this.on("touchmove", this.handleTouchmove);
        this.on("touchend", this.handleTouchend);
    }

    createEl () {
        let overlay = videojs.dom.createEl("div", {
            className: "vjs-touch-overlay",
            tabIndex: -1
        });
        let seekNote = videojs.dom.createEl("span", {
            className: "vjs-touch-seek-note"
        });
        videojs.dom.appendContent(overlay, seekNote);
        this.seekNote = seekNote;
        return overlay;
    }

    handleTouchstart (event) {
        // console.log("start==========="+event.touches[0].clientX)
        if (this.totalDuration) {
            this.addClass("vjs-touch-active");
            this.touchStateActive = true;
            this.totalWidth = this.currentWidth();
            this.startX = event.touches[0].clientX;
        }
    }

    handleTouchend () {
        this.touchStateActive = false;
        this.removeClass("vjs-touch-active");

        if (this.hasClass("vjs-touch-moving")) {
            this.removeClass("vjs-touch-moving");
            this.player().currentTime(this.toSeconds);
        }
    }

    handleTouchmove (event) {
        if (this.touchStateActive) {
            this.addClass("vjs-touch-moving");
            let currentX = event.touches[0].clientX;
            let dx = currentX - this.startX;
            let deltaX = dx / this.totalWidth;
            let currentSeconds = this.player().currentTime();
            let toSeconds = currentSeconds + deltaX * this.totalDuration;

            if (toSeconds < 0) {
                toSeconds = 0;
            } else if (toSeconds > this.totalDuration) {
                toSeconds = this.totalDuration;
            }

            let toTime = this.formatTime(toSeconds);
            toTime += "/"
            toTime += this.formatTime(this.totalDuration);
            // toTime += " w:"+this.myPlayer.currentWidth()
            // toTime += " h:"+this.myPlayer.currentHeight()
            videojs.dom.insertContent(this.seekNote, toTime);
            this.toSeconds = toSeconds;
        }
    }

    formatTime (secondsTotal) {
        secondsTotal = Math.floor(secondsTotal);
        let minutes = Math.floor(secondsTotal / 60);
        let seconds = secondsTotal % 60;
        return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    }
}
