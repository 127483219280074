import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-36d1c2e6"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  key: 2
};
const _hoisted_5 = {
  key: 3
};
const _hoisted_6 = {
  key: 4
};
const _hoisted_7 = {
  key: 5
};
const _hoisted_8 = {
  key: 6
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PFirst = _resolveComponent("PFirst");
  const _component_PNotify = _resolveComponent("PNotify");
  const _component_PBaiyuan = _resolveComponent("PBaiyuan");
  const _component_PQianke = _resolveComponent("PQianke");
  const _component_PActive = _resolveComponent("PActive");
  const _component_PLuntan = _resolveComponent("PLuntan");
  const _component_UndefinePage = _resolveComponent("UndefinePage");
  const _component_van_tab = _resolveComponent("van-tab");
  const _component_van_tabs = _resolveComponent("van-tabs");
  return _openBlock(), _createBlock(_component_van_tabs, {
    swipeable: "",
    "lazy-render": "",
    background: "#f2f2f2",
    active: $setup.active,
    "onUpdate:active": _cache[0] || (_cache[0] = $event => $setup.active = $event),
    class: "tabs",
    sticky: ""
  }, {
    "nav-left": _withCtx(() => [_createElementVNode("img", {
      class: "myimg",
      src: $setup.onlineSrc
    }, null, 8, _hoisted_1)]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.titles, (item, index) => {
      return _openBlock(), _createBlock(_component_van_tab, {
        title: item.title,
        key: index,
        "title-class": "van-tab--shrink",
        change: $options.change(index)
      }, {
        default: _withCtx(() => [index === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_PFirst)])) : index === 1 ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_PNotify)])) : index === 2 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_PBaiyuan)])) : index === 3 ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_PQianke)])) : index === 4 ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_PActive)])) : index === 5 ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_PLuntan)])) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_UndefinePage)]))]),
        _: 2
      }, 1032, ["title", "change"]);
    }), 128))]),
    _: 1
  }, 8, ["active"]);
}