import { createApp } from 'vue'
import App from './App.vue'
import { Button } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Tab, Tabs } from 'vant';
import { Form, Field,Cell, CellGroup } from 'vant';
import { PullRefresh } from 'vant';
import { List } from 'vant';
import { NavBar } from 'vant';
import { CountDown } from 'vant';
import { Divider } from 'vant';
import { Overlay } from 'vant';
import 'vant/es/dialog/style';
import 'vant/es/toast/style';
import "video.js/dist/video-js.css"
import "./assets/myvideo/myvideo.css"

import { Dialog } from 'vant';
import router from "@/router";
import axios from "axios";
import mitt from 'mitt';
import userInfo from './js/userInfo'
import {system} from "@/js/system";
const bus = mitt()

system.autoResize()


const app = createApp(App)
app.config.globalProperties.$bus = bus
app.config.globalProperties.$http = axios;
app.config.globalProperties.$userInfo = new userInfo();

app.use(router)
app.use(Button)
app.use(Swipe)
app.use(SwipeItem)
app.use(Tab)
app.use(Tabs)
app.use(Form);
app.use(Field);
app.use(Cell);
app.use(CellGroup);
app.use(PullRefresh);
app.use(List);
app.use(CountDown);
app.use(NavBar);
app.use(Dialog);
app.use(Divider);
app.use(Overlay);

app.mount('#app')