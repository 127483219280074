import {httpBg,getToken} from "@/js/http/httpBg";


function http(method, bizContent={}) {
    return httpBg({
        url: '/toodo/tdk/sys?token=' + getToken(),
        data: {
            method,
            bizContent
        }
    })
}

// 获取分类
function getAppAds(bizContent={}) {
    let p = new Promise((resolve,reject)=>{
        // console.log("bizContent:",bizContent)
        http("/getAppAds", bizContent).then(res=>{
            // console.log(res)
            resolve(res)
        }).catch(err=>{
            console.log(err)
            reject({code: err.code, msg:err.msg})
        }).finally(()=>{})
    })
    return p;
}

export const bgSys = {
    getAppAds,
}