import "core-js/modules/es.array.push.js";
import { Checkbox, CheckboxGroup, showConfirmDialog } from 'vant';
import { ref } from "vue";
export default {
  name: "CLoginFooter",
  components: {
    [Checkbox]: Checkbox,
    [CheckboxGroup]: CheckboxGroup
  },
  props: {
    isShowServer: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    }
  },
  setup() {
    const checked = ref(true);
    return {
      checked
    };
  },
  methods: {
    clkServer() {
      console.log("clkServer");
      this.$router.push({
        name: 'ServerProtocal'
      });
    },
    clkPrivacy() {
      console.log("clkPrivacy");
      this.$router.push({
        name: 'Conceal'
      });
    },
    onSubmit() {
      // console.log("footer on submit")
      // if(this.isShowServer)
      {
        if (this.checked) {
          // console.log("checked sel")
          this.$emit("submit");
        } else {
          // console.log("checked no sel")
          // showToast('校验失败');
          showConfirmDialog({
            message: '请先阅读并同意《服务协议》和《隐私保护指引》'
          }).then(() => {
            // on confirm
            this.checked = true;
          }).catch(() => {
            // on cancel
          });
        }
      }
    }
  }
};