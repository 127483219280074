import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CLoginContext = _resolveComponent("CLoginContext");
  const _component_CLoginTitle = _resolveComponent("CLoginTitle");
  const _component_CLoginFooter = _resolveComponent("CLoginFooter");
  const _component_van_dialog = _resolveComponent("van-dialog");
  return _openBlock(), _createBlock(_component_van_dialog, {
    show: $setup.show,
    width: "var(--login-width)"
  }, {
    title: _withCtx(() => [_createVNode(_component_CLoginTitle, {
      title: $data.diagleInfo[$data.mode - 1],
      onClose: $options.onClose
    }, null, 8, ["title", "onClose"])]),
    footer: _withCtx(() => [_createVNode(_component_CLoginFooter, {
      "is-show-server": false,
      title: "提交",
      onSubmit: $options.onSubmit
    }, null, 8, ["onSubmit"])]),
    default: _withCtx(() => [_createVNode(_component_CLoginContext, {
      onSendSms: $options.onSendSms,
      onSwitchMode: $options.onSwitchMode,
      mode: $data.mode,
      ref: "CLoginContext"
    }, null, 8, ["onSendSms", "onSwitchMode", "mode"])]),
    _: 1
  }, 8, ["show"]);
}