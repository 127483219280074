import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5116cfa9"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "LDFooter"
};
const _hoisted_2 = {
  class: "btn"
};
const _hoisted_3 = {
  class: "zhuceBtn"
};
const _hoisted_4 = {
  class: "cbSpan"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_button = _resolveComponent("van-button");
  const _component_van_checkbox = _resolveComponent("van-checkbox");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_button, {
    type: "primary",
    onClick: $options.onSubmit
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_3, _toDisplayString($props.title), 1)]),
    _: 1
  }, 8, ["onClick"])]), $props.isShowServer ? (_openBlock(), _createBlock(_component_van_checkbox, {
    key: 0,
    class: "ldcheckbox",
    "label-disabled": "",
    "icon-size": "var(--ld-checkbox-size)",
    "checked-color": "var(--bg-color)",
    modelValue: $setup.checked,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $setup.checked = $event),
    shape: "square"
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_4, [_createTextVNode("我已阅读并同意 "), _createElementVNode("span", {
      class: "cbSpanSel",
      onClick: _cache[0] || (_cache[0] = (...args) => $options.clkServer && $options.clkServer(...args))
    }, "服务协议"), _createTextVNode(" 和 "), _createElementVNode("span", {
      class: "cbSpanSel",
      onClick: _cache[1] || (_cache[1] = (...args) => $options.clkPrivacy && $options.clkPrivacy(...args))
    }, "隐私保护指引")])]),
    _: 1
  }, 8, ["modelValue"])) : _createCommentVNode("", true)]);
}