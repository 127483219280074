import videojs from 'video.js'

var Component = videojs.getComponent('Component');

export class CompLogo extends Component {
    constructor(player, options = {}) {
        super(player, options);
    }
    createEl() {
        return videojs.dom.createEl('div', {
            className: 'vjs-logo'
        });
    }
}
