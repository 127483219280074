export default {
  name: "adbCtx8_1",
  props: {
    title: {
      type: String,
      default: ''
    },
    context: {
      type: Array
    }
  },
  computed: {
    getContext() {
      return this.context;
    }
  }
};