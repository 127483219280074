export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "FErweima",
  props: {
    img1: {
      type: String,
      required: true
    },
    img2: {
      type: String,
      required: true
    }
  }
};