import "core-js/modules/es.array.push.js";
import { showToast } from "vant";
import CUser from "@/components/CUser";
import { bgUser } from "@/js/bgUser";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "PHead",
  components: {
    CUser
  },
  data() {
    return {
      title: '科学种植"百园千课"服务平台',
      kefu: '客服中心',
      bangzhu: '帮助',
      zhuce: '注册/登录',
      isLogin: false
    };
  },
  mounted() {
    this.$bus.on("onLoginSuccess", params => {
      console.log("onLoginSuccess");
      this.isLogin = true;
    });
  },
  unmounted() {
    this.$bus.off("onLoginSuccess");
  },
  created() {
    this.isLogin = this.$userInfo.isLogin;
  },
  methods: {
    onExitLogin() {
      this.$refs.CUser.show = false;
      console.log("退出登录");
      showToast('退出登录');
      this.$userInfo.exitLogin();
      this.isLogin = this.$userInfo.isLogin;
    },
    onModify(info) {
      let that = this;
      console.log("修改用户内容：", info);
      bgUser.updateUserInfo({
        userName: info.info.userName,
        userImg: info.info.userImg
      }).then(res => {
        console.log(res);
        that.$userInfo.userName = info.info.userName;
        that.$userInfo.userImg = info.info.userImg;
        showToast('保存成功');
      }).catch(() => {
        showToast('保存失败');
      }).finally(() => {
        this.$refs.CUser.onClose();
      });
    },
    onZhuce() {
      this.$bus.emit("onLogin", "我要登录 ");
    },
    onShowUser() {
      this.$refs.CUser.show = true;
    },
    onClientCenter() {
      this.$router.push({
        name: 'ClientCenter'
      });
    },
    onHelp() {
      this.$router.push({
        name: 'Help'
      });
    }
  }
};