import { ref } from "vue";
import CLoginTitle from "@/components/login/CLoginTitle";
import CLoginFooter from "@/components/login/CLoginFooter";
import CLoginContext from "@/components/login/CLoginContext";
export default {
  name: "CLogin",
  data() {
    return {
      mode: 1,
      diagleInfo: ['登录', '注册', '修改密码']
    };
  },
  components: {
    CLoginTitle,
    CLoginFooter,
    CLoginContext
  },
  watch: {
    show() {}
  },
  setup() {
    const show = ref(false);
    return {
      show
    };
  },
  methods: {
    onSubmit() {
      let isUsernameOk = true;
      let isSmsOk = true;
      let isPwdOk = true;
      //1. 登录
      //2. 注册
      //3. 忘记密码
      // console.log("注册 onSubmit")
      switch (this.mode) {
        //1. 登录
        case 1:
          isUsernameOk = this.$refs.CLoginContext.checkUsername();
          isPwdOk = this.$refs.CLoginContext.checkPwd();
          break;
        //2. 注册
        case 2:
          isUsernameOk = this.$refs.CLoginContext.checkUsername();
          isSmsOk = this.$refs.CLoginContext.checkSms();
          isPwdOk = this.$refs.CLoginContext.checkPwd();
          break;
        //3. 忘记密码
        case 3:
          isUsernameOk = this.$refs.CLoginContext.checkUsername();
          isSmsOk = this.$refs.CLoginContext.checkSms();
          isPwdOk = this.$refs.CLoginContext.checkPwd2();
          break;
      }
      if (isUsernameOk && isPwdOk && isSmsOk) {
        this.$emit('submit', {
          mode: this.mode,
          params: this.$refs.CLoginContext.getAllParams()
        });
      }
    },
    onClose() {
      this.show = false;
    },
    onSendSms(obj) {
      console.log(obj);
      //state: 1 触发验证发送  0 验证码失效
      if (obj.state) {
        console.log("触发验证发送");
      } else {
        console.log("验证码失效");
      }
      this.$emit('sendSms', {
        mode: this.mode,
        state: obj.state,
        params: obj.params
      });
    },
    onSwitchMode(mode) {
      console.log("mode:" + mode);
      this.mode = mode;
    }
  }
};