export default {
  name: "advTitle",
  props: {
    title: {
      type: String,
      default: 'x'
    },
    bgColor: {
      type: Number,
      default: 0
    }
  }
};