import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-40dd57ab"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "head"
};
const _hoisted_2 = {
  class: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_CUser = _resolveComponent("CUser");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($data.title), 1), _createElementVNode("button", {
    class: "kefu",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.onClientCenter && $options.onClientCenter(...args))
  }, _toDisplayString($data.kefu), 1), _createElementVNode("button", {
    class: "bangzhu",
    onClick: _cache[1] || (_cache[1] = (...args) => $options.onHelp && $options.onHelp(...args))
  }, _toDisplayString($data.bangzhu), 1), $data.isLogin ? (_openBlock(), _createBlock(_component_van_icon, {
    key: 0,
    class: "logined",
    name: "contact",
    size: "0.6rem",
    onClick: $options.onShowUser
  }, null, 8, ["onClick"])) : (_openBlock(), _createElementBlock("button", {
    key: 1,
    class: "login",
    onClick: _cache[2] || (_cache[2] = (...args) => $options.onZhuce && $options.onZhuce(...args))
  }, _toDisplayString($data.zhuce), 1))]), _createVNode(_component_CUser, {
    ref: "CUser",
    onExitLogin: $options.onExitLogin,
    onModify: $options.onModify
  }, null, 8, ["onExitLogin", "onModify"])], 64);
}