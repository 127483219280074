import { ref } from "vue";
import { utils } from "../../js/utils";
import CImgIdentifyCode from "@/components/login/CImgIdentifyCode";
import { login } from "@/js/login";
import { showToast } from 'vant';
export default {
  name: "CLoginContext",
  components: {
    CImgIdentifyCode
  },
  props: {
    mode: {
      type: Number,
      required: true
    }
  },
  watch: {
    mode() {
      this.username = '';
      this.sms = '';
      this.password = '';
      this.password2 = '';
      this.usernameErr = '';
      this.smsErr = '';
      this.pwdErr = '';
      this.pwdErr2 = '';
      this.isShowImgIdentifyCode = false;
    }
  },
  setup() {
    const username = ref('');
    const sms = ref('');
    const password = ref('');
    const password2 = ref('');
    return {
      username,
      sms,
      password,
      password2
    };
  },
  data() {
    return {
      bSmsBtnState: false,
      isShowImgIdentifyCode: false,
      imgBase: '',
      table: [
      //【手机号、验证码、密码、确认密码、登录、忘记密码、手机号注册】
      //登录
      [true, false, true, false, false, true, true],
      //注册
      [true, true, true, true, true, true, false],
      //忘记密码
      [true, true, true, true, true, false, true]],
      usernameErr: "",
      smsErr: "",
      pwdErr: "",
      pwdErr2: ""
    };
  },
  methods: {
    checkUsername() {
      if (utils.checkModbile(this.username)) {
        this.usernameErr = '';
        return true;
      } else {
        console.log("手机号不正确");
        this.usernameErr = '请输入正确的手机号';
      }
    },
    checkSms() {
      if (this.sms != '') {
        this.smsErr = '';
        return true;
      } else {
        console.log("验证码不正确");
        this.smsErr = '请输入正确的验证码';
      }
    },
    setSmsState(state) {
      if (state) {
        this.smsErr = '';
        return true;
      } else {
        console.log("验证码不正确");
        this.smsErr = '请输入正确的验证码';
      }
    },
    checkPwd() {
      this.pwdErr = '';
      this.pwdErr2 = '';
      if (this.password === '') {
        this.pwdErr = '密码不能为空';
        return false;
      } else {
        return true;
      }
    },
    checkPwd2() {
      this.pwdErr = '';
      this.pwdErr2 = '';
      if (this.password === '') {
        this.pwdErr = '密码不能为空';
        return false;
      }
      if (this.password === this.password2) {
        return true;
      } else {
        this.pwdErr2 = '确认密码不一致';
        return false;
      }
    },
    getAllParams() {
      return {
        username: this.username,
        sms: this.sms,
        password: this.password,
        password2: this.password2
      };
    },
    onSendSms() {
      //先验证手机号是否正确输入
      if (this.checkUsername()) {
        //短信验证码前获取验证图片
        this.onRefresh();
      }
    },
    beginDownCount() {
      //验证码开始倒计时
      this.bSmsBtnState = true;
      this.$nextTick(() => {
        this.$refs.countDown.reset();
        this.$refs.countDown.start();
        this.$emit("sendSms", {
          state: 1
        });
      });
    },
    onFinish() {
      this.bSmsBtnState = false;
      this.$emit("sendSms", {
        state: 0
      });
      // console.log('倒计时结束')
    },

    onSwitchMode(mode) {
      this.$emit("switchMode", mode);
    },
    //验证码
    onRefresh() {
      if (this.checkUsername()) {
        //短信验证码前获取验证图片
        login.getVerifyPhoneCode({
          tel: this.username
        }).then(res => {
          this.isShowImgIdentifyCode = true;
          this.imgBase = res.verifyCode;
        }).catch(err => {
          console.log('获取校验码失败：' + err.code);
          showToast('获取校验码失败');
        });
      }
    },
    onImgClose() {
      this.isShowImgIdentifyCode = false;
    },
    onImgConfirm(code) {
      login.verifyPhone({
        tel: this.username,
        code: code
      }).then(() => {
        // console.log(res)
        this.sendSms();
      }).catch(err => {
        console.log('校验失败：' + err.code);
        if (err.code === 10017) {
          showToast('校验失败');
        }
      }).finally(() => {});
    },
    sendSms() {
      login.sendSmsBiz({
        tel: this.username
      }).then(res => {
        console.log(res);
        this.onImgClose();
        this.beginDownCount();
      }).catch(err => {
        console.log('短信发送失败：' + err.code);
        showToast('短信发送失败');
      }).finally(() => {});
    }
  }
};