
import {createRouter} from "vue-router";
import {createWebHistory} from "vue-router";



import Home from "@/views/Home";
import Help from "@/views/Help";
import ClientCenter from "@/views/ClientCenter";

import ServerProtocal from "@/views/ServerProtocal"
import Conceal from "@/views/Conceal"

import DetailWenzhang from "@/views/DetailWenzhang"
import DetailVideo from "@/views/DetailVideo"

import Ys from "@/views/Ys"

const routes=[
    {
        path: "/",
        redirect: '/home'
    },
    // 通话手表-乐康守护跳转
    {
        path:"/ys",
        name:"Ys",
        component:Ys,
    },
    {
        path:"/home",
        name:"Home",
        component:Home,
        meta:{
          keepAlive: true,
        },
        children:[

        ]
    },
    // 帮助
    {
        path:"/help",
        name:"Help",
        component:Help,
    },
    // 客服中心
    {
        path:"/clientCenter",
        name:"ClientCenter",
        component:ClientCenter,
    },
    // 服务协议
    {
        path:"/serverProtocal",
        name:"ServerProtocal",
        component:ServerProtocal,
    },
    // 秘隐
    {
        path:"/conceal",
        name:"Conceal",
        component:Conceal,
    },

    //文章
    {
        path:"/detailWenzhang/:id",
        name:"DetailWenzhang",
        component:DetailWenzhang,
    },

    //视频
    {
        path:"/detailVideo/:id",
        name:"DetailVideo",
        component:DetailVideo,
    }
];

const router=createRouter({
    history:createWebHistory(process.env.BASE_URL),
    routes
})
export default router;