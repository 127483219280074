import adbCtx8_1 from "@/page/advSchool/adbCtx8_1";
export default {
  name: "adbCtx8",
  components: {
    adbCtx8_1
  },
  props: {
    question: {
      type: String,
      default: ''
    },
    table: {
      type: Array
    }
  }
};