import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4911b2be"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ctx8"
};
const _hoisted_2 = {
  class: "x8Q"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_adbCtx8_1 = _resolveComponent("adbCtx8_1");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.question), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.table, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index
    }, [_createVNode(_component_adbCtx8_1, {
      title: item.title,
      context: item.context
    }, null, 8, ["title", "context"])]);
  }), 128))]);
}