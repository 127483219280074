import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-303d5154"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "LDFooter"
};
const _hoisted_2 = {
  class: "btn"
};
const _hoisted_3 = {
  class: "zhuceBtn"
};
const _hoisted_4 = {
  class: "zhuceBtn"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_button = _resolveComponent("van-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_button, {
    type: "primary",
    onClick: $options.onSubmit
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_3, _toDisplayString($props.title), 1)]),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_van_button, {
    type: "primary",
    onClick: $options.onModify
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_4, _toDisplayString($props.title2), 1)]),
    _: 1
  }, 8, ["onClick"])])]);
}