
function gotoPage(that, data){
    // console.log(data.daily.id)
    switch (data.daily.type){
        //文章
        case 0:
            // console.log(data)
            that.$router.push({ name: 'DetailWenzhang', params: {id: data.daily.id, info: JSON.stringify(data) }})
            break;
        //视频
        case 1:
            that.$router.push({ name: 'DetailVideo', params: {id: data.daily.id, info: JSON.stringify(data) }})
            break;
    }
}

function gotoBack(that, flat=false){
    if(flat){
        that.$router.back()
    }else{
        that.$router.replace({ name: 'Home'})
    }
    // that.$router.back()
    // that.$router.replace({ name: 'Home'})
}

export const route = {
    gotoPage,
    gotoBack
}