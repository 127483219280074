import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3576c5b2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "lvBottomChatBox",
  ref: "lvBottomChatBox"
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  key: 2
};
const _hoisted_4 = {
  key: 0,
  class: "fillBottom"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_cell_group = _resolveComponent("van-cell-group");
  const _component_CDlgQrcode = _resolveComponent("CDlgQrcode");
  const _component_CDlgWxShare = _resolveComponent("CDlgWxShare");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "lwBottomChatBox clear-fix",
    ref: "lwBottomChatBox",
    onClick: _cache[2] || (_cache[2] = (...args) => $options.getheight && $options.getheight(...args))
  }, [!$data.isFouce ? (_openBlock(), _createBlock(_component_van_icon, {
    key: 0,
    class: "IconLeft",
    name: "arrow-left",
    size: "1.0rem",
    color: "var(--bg-color)",
    onClick: $options.onClickLeft
  }, null, 8, ["onClick"])) : _createCommentVNode("", true), _createElementVNode("div", {
    class: _normalizeClass({
      'textareaFouce': $data.isFouce,
      'textarea': !$data.isFouce
    })
  }, [_createVNode(_component_van_cell_group, {
    inset: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_van_field, {
      class: "mytextarea",
      ref: "fieldPhone",
      modelValue: $data.txtValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.txtValue = $event),
      rows: "1",
      autosize: $data.autosize,
      type: "textarea",
      size: "small",
      "arrow-direction": "down",
      placeholder: $data.placeholder,
      onFocus: $options.onFocus,
      onBlur: $options.onBlur
    }, null, 8, ["modelValue", "autosize", "placeholder", "onFocus", "onBlur"])]),
    _: 1
  })], 2), $data.isFouce ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", {
    class: "sendBtn",
    onClick: _cache[1] || (_cache[1] = (...args) => $options.onCommentDaily && $options.onCommentDaily(...args))
  }, "发送")])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_van_icon, {
    class: "Icon",
    name: "share-o",
    size: "1.0rem",
    color: "var(--bg-color)",
    onClick: $options.onShareQrcode
  }, null, 8, ["onClick"]), _createVNode(_component_van_icon, {
    class: "Icon",
    name: $props.isCollection ? 'star' : 'star-o',
    size: "1.0rem",
    color: "var(--bg-color)",
    onClick: $options.onCollectionDaily
  }, null, 8, ["name", "onClick"]), _createVNode(_component_van_icon, {
    class: "Icon",
    name: "comment-circle-o",
    size: "1.0rem",
    color: "var(--bg-color)"
  })]))], 512), $data.isBottomFill ? (_openBlock(), _createElementBlock("div", _hoisted_4)) : _createCommentVNode("", true), _createVNode(_component_CDlgQrcode, {
    ref: "CDlgQrcode"
  }, null, 512), _createVNode(_component_CDlgWxShare, {
    ref: "CDlgWxShare"
  }, null, 512)], 512);
}