import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e81d771c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ctx2"
};
const _hoisted_2 = {
  class: "ctxTitle"
};
const _hoisted_3 = {
  class: "ctxContext"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.title), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.context, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index
    }, [_createElementVNode("span", _hoisted_3, _toDisplayString(item), 1)]);
  }), 128))]);
}