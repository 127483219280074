
// 格式化时间
import {evnConfig} from "./evnConfig";

function formatTimeMills(timeMills) {
    const date = new Date(timeMills);
    let timeStr = date.getFullYear() + "_";
    if (date.getMonth() < 9) { // 月份从0开始的
        timeStr += '0';
    }
    timeStr += date.getMonth() + 1 + "_";
    timeStr += date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate();
    timeStr += '_';
    timeStr += date.getHours() < 10 ? ('0' + date.getHours()) : date.getHours();
    timeStr += '_';
    timeStr += date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes();
    timeStr += '_';
    timeStr += date.getSeconds() < 10 ? ('0' + date.getSeconds()) : date.getSeconds();
    return timeStr;
}

import OSS from "ali-oss"

let client = new OSS({
    secure: true,
    bucket: evnConfig.getOSS().bucket,
    region: evnConfig.getOSS().region,
    accessKeyId: evnConfig.getOSS().accessKeyId,
    accessKeySecret: evnConfig.getOSS().accessKeySecret,
    endpoint: evnConfig.getOSS().endpoint
});

// 上传ArrayBuffer
export function putArrayBuffer(dirName, fileName, ArrayBuffer) {
    const buffer = new OSS.Buffer(ArrayBuffer);
    return client.put(`kxzz-edu/${dirName}/${fileName}`, buffer)
}
// 上传视频
export function putFile(dirName, file) {
    let newName = file.name.replace('.', '_' + formatTimeMills(new Date().getTime()) + '.')
    return client.put(`kxzz-edu/${dirName}/${newName}`, file, {headers: {'x-oss-forbid-overwrite': false}})
}

function getOssPath(name){
    return evnConfig.getOSS().baseUrl + name
}
export default {
    getOssPath,
    putArrayBuffer,
    putFile,
}
