import { Checkbox, CheckboxGroup, showConfirmDialog } from 'vant';
import { ref } from "vue";
export default {
  name: "CUserFooter",
  components: {
    [Checkbox]: Checkbox,
    [CheckboxGroup]: CheckboxGroup
  },
  props: {
    title: {
      type: String,
      required: true
    },
    title2: {
      type: String,
      required: true
    }
  },
  setup() {
    const checked = ref(true);
    return {
      checked
    };
  },
  methods: {
    onSubmit() {
      showConfirmDialog({
        message: '是否退出登录？'
      }).then(() => {
        // on confirm
        this.$emit("submit");
      }).catch(() => {
        // on cancel
      });
    },
    onModify() {
      this.$emit("modify");
      // showConfirmDialog({
      //   message:
      //       '是否修改个人信息？',
      // })
      // .then(() => {
      //   // on confirm
      //   this.$emit("modify")
      // })
      // .catch(() => {
      //   // on cancel
      // });
    }
  }
};