import "core-js/modules/es.array.push.js";
import F01 from "@/components/F01";
// import F10 from "@/components/F10";
import F03 from "@/components/F03";
import F04 from "@/components/F04";
import FErweima from "@/components/FErweima";
import PBottom from "@/components/PBottom";
import { bgDaily } from "@/js/bgDaily";
import { bgSys } from "@/js/bgSys";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "PFirst",
  components: {
    PBottom,
    // F10,
    F01,
    F03,
    F04,
    FErweima
  },
  computed: {
    bottomImg1() {
      if (this.bottomAds.length >= 1) {
        return this.bottomAds[0].img;
      } else {
        return require("../assets/ma.gif");
      }
    },
    bottomImg2() {
      if (this.bottomAds.length >= 2) {
        return this.bottomAds[1].img;
      } else {
        return require("../assets/ma.gif");
      }
    }
  },
  data() {
    return {
      topAds: [],
      bottomAds: [],
      recomBaiyuan: [],
      recomQianke: [],
      recomAct: [],
      version: process.env.VUE_APP_Version
    };
  },
  mounted() {
    //获取顶部广告图
    this.getTopAppAds();
    //获取底部广告图
    this.getBottomAppAds();
    //获取推荐
    this.getRecommendDailies();
  },
  methods: {
    onSwipeClk(index) {
      console.log(this.topAds[index].content);
      this.$router.push({
        path: this.topAds[index].content
      });
    },
    getTopAppAds() {
      // console.log("getTopAppAds")
      bgSys.getAppAds({
        start: 0,
        limit: 5,
        position: 1
      }).then(res => {
        for (let item of res) {
          this.topAds.push(item);
        }
      });
    },
    getBottomAppAds() {
      // console.log("getBottomAppAds")
      bgSys.getAppAds({
        start: 0,
        limit: 2,
        position: 2
      }).then(res => {
        for (let item of res) {
          this.bottomAds.push(item);
        }
      });
    },
    // getClassify(){
    //   daily.getClassify({
    //     start: 0,
    //     limit: 20
    //   }).then(res=>{
    //
    //   }).catch(err=>{
    //
    //   })
    // },
    getRecommendDailies() {
      bgDaily.getRecommendDailies({
        classifies: {
          1: 1,
          //通知
          2: 1,
          //百园
          3: 3,
          //千课
          4: 4,
          //活动
          5: 1 //论坛
        }
      }).then(res => {
        // this.recommendDetail = res
        for (let key in res) {
          switch (key) {
            case '2':
              this.recomBaiyuan = res[key];
              break;
            case '3':
              this.recomQianke = res[key];
              break;
            case '4':
              // for (let item in res[key]){
              //   console.log("item:", res[key][item])
              //   this.recomAct.push(this.recomAct)
              // }
              this.recomAct = res[key];
              break;
            default:
              // console.log("default:")
              break;
          }
          // console.log(key)
        }
        // console.log("recomAct:",this.recomAct)
      });
    }
  }
};