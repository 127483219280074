import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-381124d5"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "paddingLR"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "keywords"
};
const _hoisted_4 = {
  class: "info"
};
const _hoisted_5 = {
  key: 0,
  class: "info"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($options.title), 1), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.keywords, (item, index) => {
    return _openBlock(), _createElementBlock("span", {
      class: "keyword",
      key: index
    }, _toDisplayString(item), 1);
  }), 128)), _createElementVNode("span", _hoisted_4, _toDisplayString($options.author), 1), $options.showTime ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($options.time), 1)) : _createCommentVNode("", true)])]);
}