import CLine from "@/components/CLine";
import CR04 from "@/components/CR04";
import PBottom from "@/components/PBottom";
import mixGetDetailByClassify from "@/js/mixins/mixGetDetailByClassify";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "PActive",
  components: {
    CLine,
    CR04,
    PBottom
  },
  mixins: [mixGetDetailByClassify],
  data() {
    return {
      classify: 4
    };
  }
};