import { eventInit, eventUnInit } from "@/js/eventBus";
import CLogin from "@/components/CLogin";
import md5 from "js-md5";
import { login } from "@/js/login";
import { showToast } from "vant";
import { system } from "@/js/system";
import { shareWXInit } from "@/js/weChat/wxShare";
import AdvSchool from "@/page/advSchool/AdvSchool";
import CUploadImg from "@/components/CUploadImg";
import clientOSS from "@/js/clientOSS";
export default {
  name: 'App',
  components: {
    CLogin,
    AdvSchool,
    CUploadImg
  },
  created() {
    // system.autoResize()
    this.$userInfo.loadFromStorage(this);
  },
  mounted() {
    console.log("window.location.href：", window.location.href);
    shareWXInit("科学种植", "科学种植“百园千课”服务平台", window.location.href);
    // alert('上传测试1457')
    eventInit(this);
    this.$bus.on("onLogin", params => {
      //显示登录对话框
      this.$refs.CLogin.show = true;
    });
  },
  unmounted() {
    this.$bus.off("onLogin");
    eventUnInit();
  },
  methods: {
    // bttest(){
    //   this.$refs.CUploadImg.uploadFileToOss().then(res=>{
    //     console.log("uploadFileToOss success:",res)
    //   }).catch(err=>{
    //     console.log("uploadFileToOss fail:",err)
    //   })
    // },
    onSubmit(obj) {
      switch (obj.mode) {
        case 1:
          console.log("登录----------------");
          this.userLogin({
            userType: 1,
            identifier: obj.params.username,
            pwd: md5(obj.params.password)
          });
          console.log("登录----------------2");
          break;
        case 2:
          console.log("注册----------------");
          this.userReg({
            identifier: obj.params.username,
            pwd: md5(obj.params.password),
            code: obj.params.sms
          });
          break;
        case 3:
          console.log("修改密码----------------");
          this.updateUserPwd({
            userType: 1,
            tel: obj.params.username,
            pwd: md5(obj.params.password),
            code: obj.params.sms
          });
          break;
      }
    },
    onSendSms(obj) {
      console.log("pHead->onSendSms:");
      console.log(obj);
      //0 验证码失效
      //1 验证码开始有效
      if (obj.state == 0) {
        return;
      }
    },
    userLogin(biz) {
      login.userLogin(biz).then(res => {
        this.$userInfo.updateLoginDate(biz, res);
        showToast('登录成功');
        this.$bus.emit("onLoginSuccess", "onLoginSuccess ");
        this.closeLoginDialog();
      }).catch(err => {
        showToast(err.msg);
      }).finally(() => {});
    },
    userReg(biz) {
      login.userReg(biz).then(res => {
        this.$userInfo.updateLoginDate(biz, res);
        showToast('注册成功');
        this.$bus.emit("onLoginSuccess", "onLoginSuccess ");
        this.closeLoginDialog();
      }).catch(err => {
        showToast(err.msg);
      }).finally(() => {});
    },
    updateUserPwd(biz) {
      login.updateUserPwd(biz).then(res => {
        biz.identifier = biz.tel;
        this.$userInfo.updateLoginDate(biz, res);
        this.$bus.emit("onLoginSuccess", "onLoginSuccess ");
        showToast('密码修改成功');
        this.closeLoginDialog();
      }).catch(err => {
        showToast(err.msg);
      }).finally(() => {});
    },
    closeLoginDialog() {
      this.$refs.CLogin.show = false;
    }
  }
};