import PNav from "@/page/PNav";
import PHead from "@/page/PHead";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {
    PHead,
    PNav
  },
  created() {
    // console.log("home created")
  },
  mounted() {
    // console.log(window)
  }
};