import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a07e5a86"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "page-margin paddingLR CR04"
};
const _hoisted_2 = {
  class: "context clear-fix"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CR04Item = _resolveComponent("CR04Item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.dailies, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index
    }, [_createVNode(_component_CR04Item, {
      info: item
    }, null, 8, ["info"])]);
  }), 128))])]);
}