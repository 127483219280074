
import {bgDaily} from "@/js/bgDaily";

let mixGetComment = {
    data(){
        return {
            count: 0,
            loading: false,
            finished: false,

            start: 0,
            limit: 10,

            dailies: []
        }
    },
    created() {
        // console.log("========"+this.$options.name)
        this.loading = true
        // this.getDetailLoad()
    },
    methods:{
        onLoad(){
            setTimeout(() => {
                if (this.refreshing) {
                    this.refreshing = false;
                }
                this.getDetailLoad()
            }, 300);
        },
        getDetailLoad(){
            bgDaily.getDailyComment({
                id: this.id,
                type: this.type,
                userId: this.userId,
                order: 1,
                start: this.start,
                limit: this.limit
            }).then(res=>{
                // console.log(res)
                for (let index in res.comments){
                    // console.log(res.dailies[index])
                    this.dailies.push(res.comments[index])
                }
                if(res.comments.length === 0){
                    this.finished = true;
                }
                // console.log("获取评论长度：",res.comments.length)
                this.start += res.comments.length
            }).catch(()=>{
                console.log("catch==================================")
            }).finally(()=>{
                this.count++
                this.loading = false;
                // console.log("finally======================"+this.count+"  name:",this.$options.name)
            })
        },
        beginLoad(){
            this.$nextTick(()=>{
                this.getDetailLoad()
            })
        },
        addComment(comment){
            // content,    //用户评论，礼物类型评论 0代表鲜花  (string)
            // userName, //用户名字    (string)
            // userImg, //用户头像 (string)
            // created_at, //评论时间
            this.dailies.unshift(comment)
        }
    }

}

export default mixGetComment