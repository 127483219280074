import tabCtrl from "@/page/advSchool/tabCtrl";
import aodaoliya from "@/page/advSchool/aodaoliya";
import jialada from "@/page/advSchool/jialada";
export default {
  name: "AdvSchool",
  components: {
    tabCtrl,
    aodaoliya,
    jialada
  },
  data() {
    return {
      index: 0,
      titles: [{
        id: 0,
        name: '澳大利亚高中'
      }, {
        id: 1,
        name: '加拿大中学'
      }]
    };
  },
  methods: {
    tabClick(index) {
      this.index = index;
      console.log(index);
    }
  }
};