export default {
  name: "adbCtx4",
  props: {
    title: {
      type: String,
      default: ''
    },
    context: {
      type: String,
      default: ''
    },
    ta: {
      type: Array
    },
    ca: {
      type: Array
    }
  }
};