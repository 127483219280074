
//环境配置
//******************************************************b
const env = ''
const ENV_PROD = 0
const ENV_DEV = 1
const ENV_TEST = 2

const config = {
    getEnv: function () {
        switch (env ? env : location.hostname) {
            case 'kxzz-edu.com.cn':
                return ENV_PROD;

            default:
            case 'kepu.web.toodo.com.cn': //测试tddev.toodo
                return ENV_DEV;
        }
        // eslint-disable-next-line no-unreachable
        return ENV_TEST;
    },
    ENV_PROD,
    ENV_DEV,
    ENV_TEST,
};
//******************************************************e


//分享下载或打开APP信息
//******************************************************b
const PRO_APP_ADDR = {
    // href: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.toodo.toodo&fromcase=40002'
    // href: 'https://www.toodo.com.cn/index/science_download/index.html'
    href: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.toodo.popularization&fromcase=40002'
};
function getAppInfo() {
    return PRO_APP_ADDR
}
//******************************************************e


//OSS资源上传信息
//******************************************************b
//开发测试环境
const DEV_OSS = {
    baseUrl: 'https://app.kepuoss2.toodo.com.cn/',
    bucket: 'kepu-test',
    region: 'oss-cn-shenzhen',
    accessKeyId: 'LTAI5tAayh4mbx5YUZZMqZ99',
    accessKeySecret: '11eoltbOnzIHsAEvPmfvr4M47ESHWv',
    endpoint: "oss-cn-shenzhen.aliyuncs.com"
};
//生产环境
const PRO_OSS = {
    baseUrl: 'https://kepu.oss.toodo.com.cn/',
    bucket: 'kepu-app',
    region: 'oss-cn-shenzhen',
    accessKeyId: 'LTAI5tGXNdnXCJB1kdeiDRSi',
    accessKeySecret: 'ynULwEUYdpVwaQo3ipXxJiFv0LR4fr',
    endpoint: "oss-cn-shenzhen.aliyuncs.com"
};
//获取OSS信息
function getOSS() {
    switch (config.getEnv()) {
        case config.ENV_PROD:
            return PRO_OSS
        case config.ENV_DEV:
            return DEV_OSS
    }
    return DEV_OSS
}
//******************************************************e


// APP API 接口请求密钥、ID
//******************************************************b
const SECRET = "adglsa@#+==jfEJFOH%^!@$@1ALlsdasfdoSA";
const APP_ID = '3000';
const LOGIN_CONTINUE_TIME = 30*60*1000             //30分钟
const appAPIConfig = {
    SECRET: function (){
        return SECRET
    },
    APP_ID: function (){
        return APP_ID
    },
    LOGIN_CONTINUE_TIME: function (){
        return LOGIN_CONTINUE_TIME
    }
}
//******************************************************e


//APP-api接口地址--后台接口
//******************************************************b
//开发测试环境
const DEV_HOST_APP_API = 'https://tddev.toodo.com.cn/arboretum/api'
const TEST_HOST_APP_API = DEV_HOST_APP_API
//生产环境
const PRO_HOST_APP_API = 'https://toodo.com.cn/arboretum/api'
//获取APP api接口地址
function getAppAPIBaseUrl() {
    switch (config.getEnv()) {
        case config.ENV_PROD:
            return PRO_HOST_APP_API
        case config.ENV_DEV:
            return DEV_HOST_APP_API
    }
    return TEST_HOST_APP_API
}
//******************************************************e

//微信公众号-api接口地址--后台接口
//******************************************************b
//开发测试环境
// const DEV_HOST_WECHAT_API = 'https://tddev.toodo.com.cn/wechatForKepu/api/'
const DEV_HOST_WECHAT_API = 'https://tddev.toodo.com.cn/wechatForKepu/api/'
const TEST_HOST_WECHAT_API = DEV_HOST_APP_API
//生产环境
const PRO_HOST_WECHAT_API = 'https://toodo.com.cn/wechatForKepu/api/'
//获取APP api接口地址
function getWechatAPIBaseUrl() {
    switch (config.getEnv()) {
        case config.ENV_PROD:
            return PRO_HOST_WECHAT_API
        case config.ENV_DEV:
            return DEV_HOST_WECHAT_API
    }
    return TEST_HOST_WECHAT_API
}
//******************************************************e

//页面分享地址
//******************************************************b
//开发测试环境
const DEV_HOST_WEB = 'https://kepu-web.toodo.com.cn'
const TEST_HOST_WEB = 'https://kepu-web.toodo.com.cn'
//生产环境
const PRO_HOST_WEB = 'http://kxzz-edu.com.cn'
//分享页面时，使用
function getWebBaseUrl() {
    switch (config.getEnv()) {
        case config.ENV_PROD:
            return PRO_HOST_WEB
        case config.ENV_DEV:
            return DEV_HOST_WEB
    }
    return TEST_HOST_WEB
}
//生成分享地址
function makeShareUrl(uri){
    // return 'https://tddev.toodo.com.cn/webkepu/'+uri
    return getWebBaseUrl()+uri
}
//******************************************************e


export const evnConfig = {
    appAPIConfig,
    makeShareUrl,
    getAppAPIBaseUrl,
    getWechatAPIBaseUrl,
    getOSS,
    getAppInfo,
}
