import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d4efe8c2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "page-margin"
};
const _hoisted_2 = {
  class: "my-swipe"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  style: {
    "color": "var(--color-text3)",
    "font-size": "0.2rem"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_swipe_item = _resolveComponent("van-swipe-item");
  const _component_van_swipe = _resolveComponent("van-swipe");
  const _component_F01 = _resolveComponent("F01");
  const _component_F03 = _resolveComponent("F03");
  const _component_F04 = _resolveComponent("F04");
  const _component_FErweima = _resolveComponent("FErweima");
  const _component_PBottom = _resolveComponent("PBottom");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_swipe, {
    autoplay: 3000,
    "lazy-render": ""
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.topAds, (item, index) => {
      return _openBlock(), _createBlock(_component_van_swipe_item, {
        key: item.id,
        onClick: $event => $options.onSwipeClk(index)
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          class: "swipeImg",
          src: item.img
        }, null, 8, _hoisted_3)]),
        _: 2
      }, 1032, ["onClick"]);
    }), 128))]),
    _: 1
  })]), _createVNode(_component_F01, {
    title: "百园风采",
    detail: $data.recomBaiyuan
  }, null, 8, ["detail"]), _createVNode(_component_F03, {
    title: "千课讲堂",
    detail: $data.recomQianke
  }, null, 8, ["detail"]), _createVNode(_component_F04, {
    title: "科普活动",
    detail: $data.recomAct
  }, null, 8, ["detail"]), _createVNode(_component_FErweima, {
    img1: $options.bottomImg1,
    img2: $options.bottomImg2
  }, null, 8, ["img1", "img2"]), _createVNode(_component_PBottom), _createElementVNode("span", _hoisted_4, _toDisplayString($data.version), 1)]);
}