import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-464a8434"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ctx9 clear-fix"
};
const _hoisted_2 = {
  class: "x9Ctx"
};
const _hoisted_3 = {
  class: "x9Title"
};
const _hoisted_4 = {
  class: "x9Title1"
};
const _hoisted_5 = {
  class: "x9Title2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.titleEn), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString($props.titleCn1), 1), _createElementVNode("div", _hoisted_5, _toDisplayString($props.titleCn2), 1)])]);
}