import { ref } from "vue";
export default {
  name: "CDlgWxShare",
  setup() {
    const show = ref(false);
    return {
      show
    };
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onBeforeClose() {
      this.onClose();
    }
  }
};