export default {
  name: "CImgIdentifyCode",
  props: {
    show: {
      type: Boolean,
      required: true
    },
    imgBase: {
      type: String,
      required: true
    }
  },
  watch: {
    imgBase(newVal) {
      if (newVal) {
        this.code = '';
      }
    }
  },
  data() {
    return {
      code: ''
    };
  },
  methods: {
    onRefresh() {
      this.code = '';
      this.$emit("refresh");
    },
    beforeClose(action) {
      console.log("action:" + action);
      if ("confirm" === action) {
        this.$emit("imgConfirm", this.code);
      } else {
        this.$emit('imgClose');
      }
      // done()
    }
  }
};