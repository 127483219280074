import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CQrcode = _resolveComponent("CQrcode");
  const _component_van_dialog = _resolveComponent("van-dialog");
  return _openBlock(), _createBlock(_component_van_dialog, {
    show: $setup.show,
    width: "8rem",
    title: "打开微信，扫一扫",
    beforeClose: $options.onBeforeClose,
    confirmButtonText: "关闭"
  }, {
    default: _withCtx(() => [_createVNode(_component_CQrcode)]),
    _: 1
  }, 8, ["show", "beforeClose"]);
}