import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a619fbd4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "minHeight"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CLine = _resolveComponent("CLine");
  const _component_CR01 = _resolveComponent("CR01");
  const _component_van_list = _resolveComponent("van-list");
  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
  const _component_PBottom = _resolveComponent("PBottom");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_pull_refresh, {
    modelValue: _ctx.refreshing,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.refreshing = $event),
    onRefresh: _ctx.onRefresh
  }, {
    default: _withCtx(() => [_createVNode(_component_van_list, {
      loading: _ctx.loading,
      "onUpdate:loading": _cache[0] || (_cache[0] = $event => _ctx.loading = $event),
      finished: _ctx.finished,
      "finished-text": "没有更多了",
      onLoad: _ctx.onLoad
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dailies, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index
        }, [_createVNode(_component_CLine), _createVNode(_component_CR01, {
          info: item
        }, null, 8, ["info"])]);
      }), 128))])]),
      _: 1
    }, 8, ["loading", "finished", "onLoad"])]),
    _: 1
  }, 8, ["modelValue", "onRefresh"]), _createVNode(_component_PBottom)], 64);
}