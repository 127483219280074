import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-71c97ee0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ctx8_1"
};
const _hoisted_2 = {
  key: 0,
  class: "c81Title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.title != '' ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.title), 1)) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.context, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "c81Context",
      key: index
    }, _toDisplayString(item), 1);
  }), 128))]);
}