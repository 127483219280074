import CCommentItem from "@/components/CComment/CCommentItem";
import { ref } from "vue";
import mixGetComment from "@/js/mixins/mixGetComment";
export default {
  name: "CComment",
  components: {
    CCommentItem
  },
  setup() {
    const checked = ref(true);
    return {
      checked
    };
  },
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  mixins: [mixGetComment],
  computed: {
    id() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.info.hasOwnProperty('id')) {
        return this.info.id;
      } else {
        return 1;
      }
    },
    type() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.info.hasOwnProperty('type')) {
        return this.info.type;
      } else {
        return 0;
      }
    },
    userId() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.info.hasOwnProperty('userId')) {
        return this.info.userId;
      } else {
        return 0;
      }
    },
    commentNum() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.info.hasOwnProperty('commentNum')) {
        return this.info.commentNum;
      } else {
        return 0;
      }
    }
  },
  data() {
    return {
      classify: 1,
      isTime: true
    };
  },
  methods: {
    onSelModeTime() {
      this.isTime = true;
    },
    onSelModeHot() {
      this.isTime = false;
    }
  }
};