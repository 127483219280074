import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  return _openBlock(), _createBlock(_component_van_nav_bar, {
    class: "cbacknav",
    placeholder: "",
    title: $props.title,
    "left-arrow": "",
    onClickLeft: $options.onClickLeft
  }, null, 8, ["title", "onClickLeft"]);
}