import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-447e28bf"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "jCtx4 clear-fix"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_adjCtx4_1 = _resolveComponent("adjCtx4_1");
  const _component_adjCtx4_2 = _resolveComponent("adjCtx4_2");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_adjCtx4_1, {
    context: "BC省"
  }), _createVNode(_component_adjCtx4_1, {
    context: "安省",
    color: 1
  }), _createVNode(_component_adjCtx4_1, {
    context: "阿尔伯特省",
    color: 2
  }), _createVNode(_component_adjCtx4_2, {
    context: ['毕业学分：80', '必须：52', '选修：28']
  }), _createVNode(_component_adjCtx4_2, {
    context: ['毕业学分：30', '必须：18', '选修：12']
  }), _createVNode(_component_adjCtx4_2, {
    context: ['毕业学分：100', '必须：56', '选修：44']
  }), _createVNode(_component_adjCtx4_2, {
    context: ['完成30个小时社区服务']
  }), _createVNode(_component_adjCtx4_2, {
    context: ['完成40个小时社区服务']
  }), _createVNode(_component_adjCtx4_2, {
    context: ['无                         .']
  }, null, 8, ["context"])]);
}