import adjCtx4_1 from "@/page/advSchool/adjCtx4_1";
import adjCtx4_2 from "@/page/advSchool/adjCtx4_2";
export default {
  name: "adjCtx4",
  components: {
    adjCtx4_1,
    adjCtx4_2
  },
  props: {
    context: {
      type: String,
      default: ''
    }
  }
};