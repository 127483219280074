import { utils } from "@/js/utils";
export default {
  name: "CWZTitle",
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  computed: {
    showTime() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.info.hasOwnProperty('classify')) {
        if (this.info.classify === 1) {
          return false;
        }
      }
      return true;
    },
    title() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.info.hasOwnProperty('title')) {
        return this.info.title;
      } else {
        return '';
      }
    },
    keywords() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.info.hasOwnProperty('keywords')) {
        if (this.info.keywords.length == 0) {
          // console.log("length=0")
          return [];
        }
        let ar = this.info.keywords.split("|");
        // console.log(ar.length)
        // console.log("keywords:", ar)
        return ar;
      } else {
        return [];
      }
    },
    author() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.info.hasOwnProperty('author')) {
        return this.info.author;
      } else {
        return '';
      }
    },
    time() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.info.hasOwnProperty('time')) {
        return utils.date("Y年m月d日", this.info.time / 1000);
      } else {
        return '';
      }
    }
  }
};