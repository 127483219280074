import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-950d35a8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "page-margin paddingLR F01"
};
const _hoisted_2 = {
  class: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.title), 1), _renderSlot(_ctx.$slots, "context", {}, undefined, true)]);
}