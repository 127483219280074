import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1b4f2c76"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "position": "relative"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", {
    class: _normalizeClass(["adbTitle", {
      'adbTitleBgColor': $props.bgColor === 1
    }])
  }, _toDisplayString($props.title), 3)]);
}