import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d3f95f8e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "jCtx3"
};
const _hoisted_2 = {
  class: "j3Table"
};
const _hoisted_3 = {
  class: "j3Context"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createTextVNode(" 2023学年入学时间 "), _createElementVNode("table", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.obj, (item, index) => {
    return _openBlock(), _createElementBlock("tr", {
      class: "j3Ctx",
      key: index
    }, [_createElementVNode("td", {
      class: _normalizeClass(["j3Title", {
        'j3Title2': index & 1 === 1
      }])
    }, _toDisplayString(item.title), 3), _createElementVNode("td", _hoisted_3, _toDisplayString(item.context), 1)]);
  }), 128))])]);
}