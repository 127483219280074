import {httpBg,getToken} from "@/js/http/httpBg";
import md5 from "js-md5";
import {evnConfig} from "@/js/evnConfig";

function http(method, bizContent={}) {
    return httpBg({
        url: '/toodo?token=' + getToken(),
        data: {
            method,
            bizContent
        }
    })
}

// 短信验证码前获取验证图片
function getVerifyPhoneCode(bizContent={}) {
    let p = new Promise((resolve,reject)=>{
        bizContent.areaCode=''
        // console.log(bizContent)
        http("/getVerifyPhoneCode", bizContent).then(res=>{
            // console.log("getVerifyPhoneCode"+"success")
            // console.log(res)
            resolve(res)
        }).catch(err=>{
            console.log("getVerifyPhoneCode"+"fail")
            console.log(err)
            reject({code: err.code, msg:err.msg})
        }).finally(()=>{})
    })
    return p;
}

// 验证图片校验码
// tel,        //电话号码 (string)
// areaCode,   //区号 (string)
// code,       //校验码
function verifyPhone(bizContent={}) {
    let p = new Promise((resolve,reject)=>{
        bizContent.areaCode=''
        // console.log(bizContent)
        http("/verifyPhone", bizContent).then(res=>{
            // console.log("verifyPhone:"+"success")
            // console.log(res)
            resolve(res)
        }).catch(err=>{
            console.log("verifyPhone:"+"fail")
            console.log(err)
            reject({code: err.code, msg:err.msg})
        }).finally(()=>{})
    })
    return p;
}

//发送验证码
//====================================================
// tel,        //电话号码 (string)
// areaCode,   //区号 (string)
//********************************
// sign,       //签名 MD5(appId+tel+ts+appSecret)
// ts,         //时间戳 (string)
//====================================================
function sendSmsBiz(bizContent={}) {
    let p = new Promise((resolve,reject)=>{
        bizContent.ts = new Date().getTime()
        let signCode = ''
        signCode += bizContent.tel
        signCode += bizContent.ts
        signCode += 'saf&(*dfao===pa123afdaoo===='
        bizContent.sign = md5(signCode)

        // console.log("signCode:"+signCode)
        // console.log(bizContent)
        http("/sendSmsBiz", bizContent).then(res=>{
            // console.log("sendSmsBiz"+"success")
            // console.log(res)
            resolve(res)
        }).catch(err=>{
            console.log("sendSmsBiz"+"fail")
            console.log(err)
            reject({code: err.code, msg:err.msg})
        }).finally(()=>{})
    })
    return p;
}

//用户注册
//====================================================
//# identifier,       //唯一标识 手机号  (string)
//# areaCode,   //区号 (string)
//# pwd,    //密码 MD5(密码)     (string)
//# devID,  //手机设备ID     (string)
//# code,       //验证码 MD5(密码) (string)
// devOs,        //设备系统android ios       (string)
// devModel,     //设备名        (string)
//# source,     //前端来源 0:标准App 1:网页 (integer)
//====================================================
function userReg(bizContent={}) {
    let p = new Promise((resolve,reject)=>{

        bizContent.source = 1
        bizContent.devID = ''
        bizContent.areaCode = ''
        // bizContent.pwd = md5(bizContent.pwd)
        bizContent.code = md5(bizContent.code)
        // console.log(bizContent)
        http("/userReg", bizContent).then(res=>{
            // console.log(res)
            resolve(res)
        }).catch(err=>{
            console.log(err)
            reject({code: err.code, msg:err.msg})
        }).finally(()=>{})
    })
    return p;
}

//用户登录(包含第三方授权注册)
//通用参数
// devID,        //手机设备ID     (string)
// devOs,        //设备系统android ios       (string)
// devModel,     //设备名        (string)
//# source,     //前端来源 0:标准App 1:网页 (integer)
// //自动登录
// session,      //持久化登录标志 (string)
// //手机登录
//# userType,     //登录类型 1:手机登录 2:验证码登录 3:微信授权     (unsignedTinyInteger)
//# identifier,       //唯一标识 手机号或者第三方openid  (string)
//# areaCode,   //区号 (string)
//# pwd,        //密码 MD5(密码)     (string)
// //验证码登录
// userType,     //登录类型 1:手机登录 2:验证码登录 3:微信授权     (unsignedTinyInteger)
// identifier,       //唯一标识 手机号或者第三方openid  (string)
// areaCode,   //区号 (string)
// code,       //验证码 (string)
function userLogin(bizContent={}) {
    let p = new Promise((resolve,reject)=>{
        // console.log("userLogin***8")
        bizContent.source = 1
        bizContent.areaCode = ''
        bizContent.devID = ''
        // bizContent.pwd = md5(bizContent.pwd)
        http("/userLogin", bizContent).then(res=>{
            // console.log(res)
            resolve(res)
        }).catch(err=>{
            console.log(err)
            reject({code: err.code, msg:err.msg})
        }).finally(()=>{})
    })
    return p;
}

// 找回密码
// tel,        //电话号码 (string)
// areaCode,   //区号 (string)
// pwd,        //密码 MD5(密码) (string)
// code,       //验证码 (string)
// sign,       //签名 MD5(appId+tel+pwd+code+ts+appSecret)
// ts,         //时间戳 (string)
// devID,        //手机设备ID     (string)
// devOs,        //设备系统android ios       (string)
// devModel,     //设备名        (string)
// source,     //前端来源 0:标准App 1:网页 (integer)
function updateUserPwd(bizContent={}) {
    let p = new Promise((resolve,reject)=>{
        bizContent.code = md5(bizContent.code)
        bizContent.source = 1
        bizContent.devID = ''
        bizContent.areaCode = ''
        bizContent.ts = new Date().getTime()

        let signCode = evnConfig.appAPIConfig.APP_ID()
        signCode += bizContent.tel
        signCode += bizContent.pwd
        signCode += bizContent.code
        signCode += bizContent.ts
        signCode += evnConfig.appAPIConfig.SECRET()
        bizContent.sign = md5(signCode)
        console.log("signCode:",signCode)
        console.log("修改密码")
        console.log(bizContent)
        http("/updateUserPwd", bizContent).then(res=>{
            console.log(res)
            resolve(res)
        }).catch(err=>{
            console.log(err)
            reject({code: err.code, msg:err.msg})
        }).finally(()=>{})
    })
    return p;
}

// 获取持续登录token，用于收到登录失效错误码时检验是否账号被其它设备登录
function bgGetToken(bizContent={}) {
    let p = new Promise((resolve,reject)=>{
        bizContent.devID = ''
        http("/getToken", bizContent).then(res=>{
            // console.log(res)
            resolve(res)
        }).catch(err=>{
            console.log(err)
            reject({code: err.code, msg:err.msg})
        }).finally(()=>{})
    })
    return p;
}


export const login = {
    getVerifyPhoneCode,
    verifyPhone,
    sendSmsBiz,
    userReg,
    userLogin,
    updateUserPwd,
    bgGetToken
}





// private static int[] countryCode = new int[]{
//     86, 93, 355, 213, 376,//中国,阿富汗,阿尔巴尼亚,阿尔及利亚,安道尔,
//         244, 1264, 1268, 54, 374,//安哥拉,安圭拉岛(英),安提瓜和巴布达,阿根廷,亚美尼亚,
//         297, 247, 61, 43, 994,//阿鲁巴岛,阿森松(英),澳大利亚,奥地利,阿塞拜疆,
//         1242, 973, 880, 1246, 375,//巴哈马国,巴林,孟加拉国,巴巴多斯,白俄罗斯,
//         32, 501, 229, 1441, 975,//比利时,伯利兹,贝宁,百慕大群岛(英),不丹,
//         591, 267, 55, 673, 359,//玻利维亚,博茨瓦纳,巴西,文莱,保加利亚,
//         226, 257, 237, 1, 34,//布基纳法索,布隆迪,喀麦隆,加拿大,加那利群岛(西),
//         238, 235, 236, 56, 1345,//佛得角,乍得,中非,智利,开曼群岛(英),
//         619164, 619162, 57, 1767, 269,//圣诞岛,科科斯岛,哥伦比亚,多米尼加联邦,科摩罗,
//         242, 682, 506, 385, 53,//刚果,科克群岛(新),哥斯达黎加,克罗地亚,古巴,
//         357, 420, 45, 246, 298,//塞浦路斯,捷克,丹麦,迪戈加西亚,法罗群岛,
//         299, 253, 1809, 593, 20,//格陵兰岛,吉布提,多米尼加共和国,厄瓜多尔,埃及,
//         503, 240, 372, 251, 291,//萨尔瓦多,赤道几内亚,爱沙尼亚,埃塞俄比亚,厄立特里亚,
//         500, 679, 358, 33, 594,//福克兰群岛,斐济,芬兰,法国,法属圭亚那,
//         241, 220, 995, 49, 233,//加蓬,冈比亚,格鲁吉亚,德国,加纳,
//         350, 30, 1473, 1671, 502,//直布罗陀(英),希腊,格林纳达,关岛(美),危地马拉,
//         245, 590, 224, 592, 509,//几内亚比绍,瓜得罗普岛(法),几内亚,圭亚那,海地,
//         504, 36, 354, 353, 91,//洪都拉斯,匈牙利,冰岛,爱尔兰,印度,
//         62, 98, 964, 972, 39,//印度尼西亚,伊朗,伊拉克,以色列,意大利,
//         225, 1876, 81, 962, 855,//科特迪瓦,牙买加,日本,约旦,柬埔寨,
//         7, 254, 996, 686, 850,//哈萨克斯坦,肯尼亚,吉尔吉斯斯坦,基里巴斯,朝鲜,
//     965, 856, 371, 961, 266,//科威特,老挝,拉脱维亚,黎巴嫩,莱索托,
//     231, 218, 4175, 370, 352,//利比里亚,利比亚,列支敦士登,立陶宛,卢森堡,
//     261, 265, 60, 960, 223,//马达加斯加,马拉维,马来西亚,马尔代夫,马里,
//     356, 1670, 692, 596, 230,//马耳他,马里亚纳群岛,马绍尔群岛,马提尼克(法),毛里求斯,
//     269, 222, 691, 52, 1808,//马约特岛,毛里塔尼亚,密克罗尼西亚,墨西哥,中途岛(美),
//     373, 377, 212, 258, 95,//摩尔多瓦,摩纳哥,摩洛哥,莫桑比克,缅甸,
//     389, 976, 264, 674, 977,//马其顿共和国,蒙古,纳米比亚,瑙鲁,尼泊尔,
//     31, 64, 505, 227, 234,//荷兰,新西兰,尼加拉瓜,尼日尔,尼日利亚,
//     683, 672, 47, 968, 92,//纽埃岛(新),诺福克岛(澳),挪威,阿曼,巴基斯坦,
//     680, 507, 595, 51, 63,//帕劳,巴拿马,巴拉圭,秘鲁,菲律宾,
//     48, 351, 35196, 35191, 1787,//波兰,葡萄牙,马德拉群岛(萄),亚速尔群岛(萄),波多黎各(美),
//     974, 262, 40, 7, 250,//卡塔尔,留尼旺岛(法),罗马尼亚,俄罗斯,卢旺达,
//     684, 685, 378, 966, 221,//东萨摩亚(美),西萨摩亚,圣马力诺,沙特阿拉伯,塞内加尔,
//     248, 232, 65, 421, 386,//塞舌尔,塞拉利昂,新加坡,斯洛伐克,斯洛文尼亚,
//     677, 27, 252, 82, 34,//所罗门群岛(英),南非,索马里,韩国,西班牙,
//     94, 290, 1758, 1784, 249,//斯里兰卡,圣赫勒拿,圣卢西亚,圣文森特岛(英),苏丹,
//     597, 268, 46, 41, 963,//苏里南,斯威士兰,瑞典,瑞士,叙利亚,
//     7, 255, 66, 228, 690,//塔吉克斯坦,坦桑尼亚,泰国,多哥,托克劳群岛(新),
//     676, 216, 90, 993, 688,//汤加,突尼斯,土耳其,土库曼斯坦,图瓦卢,
//     256, 44, 380, 598, 1,//乌干达,英国,乌克兰,乌拉圭,美国(本土),
//     1808, 907, 998, 678, 3906698,//夏威夷,阿拉斯加,乌兹别克斯坦,瓦努阿图,梵蒂冈,
//     58, 84, 1284, 1340, 1808,//委内瑞拉,越南,维尔京群岛(英),维京京群岛(美),威克岛(美),
//     967, 381, 243, 260, 263,//也门,南斯拉夫,扎伊尔,赞比亚,津巴布韦,
//     259, 969, 689, 675, 1681,//桑给巴尔,原民主也门地区,法属波里尼西亚,巴布亚新几内亚,瓦里斯和富士那群岛,
//     852, 853, 239, 306, 971,//香港,澳门,圣多美和普林西比,(马尔维纳斯群岛),阿拉伯联合酋长国,
//     689, 967, 387, 1649, 1868,//波利尼西亚,原阿拉伯也门地区,波斯尼亚和黑塞哥维那,特克斯和凯科斯群岛,特立尼达和多巴哥,
//     508, 64672, 599, 687, 886,//圣皮埃尔岛及密克隆岛,南极,荷属安的列斯群岛,新喀里多尼亚群岛(法),台湾,
//     1664, 1869,//蒙特塞拉特岛(英),圣克里斯托弗和尼维斯,
// };