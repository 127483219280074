// import CBackNav from "@/components/CBackNav";
import { bgDaily } from "@/js/bgDaily";
import { route } from "@/js/route";
import CHtmlPanel from "@/components/CHtmlPanel";
import CWZTitle from "@/components/CWZTitle";
import CBChat from "@/components/CBChat";
import CComment from "@/components/CComment/CComment";
import { showToast } from "vant";
export default {
  name: "DetailWenzhang",
  components: {
    // CBackNav,
    CHtmlPanel,
    CWZTitle,
    CBChat,
    CComment
  },
  data() {
    return {
      info: {
        title: '空'
      },
      isCollection: false,
      isFromHome: false
    };
  },
  created() {
    // console.log('DetailWenzhang create');
    //输入的参数
    // console.log(this.$route.params)
    // console.log(this.$route.query)
  },
  mounted() {
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty("info")) {
      this.isFromHome = true;
    }

    // eslint-disable-next-line no-prototype-builtins
    // if(this.$route.params.hasOwnProperty("info")){
    //   // console.log("对象中，包含data")
    //   let obj = JSON.parse(this.$route.params.info)
    //   this.info = obj.daily
    //   this.isCollection = obj.isCollection
    //   this.$refs.CComment.beginLoad()
    //   // console.log(this.info)
    // }else
    {
      // console.log("对象中，不包含data, 重新获取")
      bgDaily.getDaily({
        id: this.$route.params.id
      }).then(res => {
        this.info = res.daily;
        this.isCollection = res.isCollection;
        this.$refs.CComment.beginLoad();
      }).catch(() => {
        route.gotoBack(this, this.isFromHome);
      });
    }
  },
  computed: {
    getHtml() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.info.hasOwnProperty('contentText')) {
        let obj = JSON.parse(this.info.contentText);
        return obj.url;
      } else {
        return '';
      }
    }
  },
  methods: {
    //收藏
    onCollectionDaily() {
      bgDaily.collectionDaily({
        id: this.info.id,
        verify: !this.isCollection
      }).then(res => {
        this.isCollection = res.collection.verify;
        //通知上一级收藏状态
      });
    },

    onCommentDaily(obj) {
      bgDaily.commentDaily({
        id: this.info.id,
        content: obj.content
      }).then(res => {
        showToast('发送成功');
        this.info.commentNum++;
        // content:"寻找古植物王国：一场穿越2.5亿年的地质学旅行"
        // contentId:21
        // created_at:"2023-05-16 14:43:37"
        // id:4

        // content,    //用户评论，礼物类型评论 0代表鲜花  (string)
        // userName, //用户名字    (string)
        // userImg, //用户头像 (string)
        // created_at, //评论时间

        this.$refs.CComment.addComment({
          userImg: this.$userInfo.userImg,
          userName: this.$userInfo.userName,
          content: res.comment.content,
          created_at: res.comment.created_at
        });
      });
    }
  }
};