import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c5d4f700"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "my-video-area"
};
const _hoisted_2 = {
  ref: "myVideoPlayer",
  playsinline: "true",
  "webkit-playsinline": "true",
  "x5-video-player-type": "h5-page",
  "x5-video-player-fullscreen": "true",
  class: "video-js vjs-big-play-centered vjs-fluid vjs-16-9"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("video", _hoisted_2, null, 512)]);
}