const normalOptions = {
    // 布尔类型 false ，等同于 video 标签上没有 autoplay 属性。表示不用自动播放
    // 布尔类型 true ，等同于在video标签上添加了autoplay 属性，表示需要自动播放
    // 字符串类型 ‘muted’, 将会把播放器设置成静音，然后调用play() 实现自动播放
    // 字符串类型 ‘play’, 将会调用play()实现自动播放。
    // 字符串类型 ‘any’, 将会调用play实现自动播放，如果不能播放，则会把播放器设置成静音状态，再调用play()
    autoplay: false,
    //-----------------------------
    // 控制条，默认是true，设置为false后，将隐藏控制条。
    controls: true,
    //-----------------------------
    // 循环播放
    loop: false,
    //-----------------------------
    // 封面，值为图片的url链接
    // poster: '',
    //-----------------------------
    // 静音
    muted: false,
    //-----------------------------
    // ‘auto’ 立即下载视频资源（如果浏览器支持的话），有些手机浏览器节省带宽，会禁止提前下载。
    // ‘metadata’ 只下载视频的 meta 数据，meta 数据包含视频的时长和大小，有时候是通过下载几帧视频数据来得到meta的相关信息。
    // ‘none’ 不用提前下载任何数据，只有用户点击播放后才开始下载数据
    preload: 'metadata',
    //-----------------------------
    // 视频的url链接
    src: '',
    //-----------------------------
    // 设置为true，播放器的的大小将不再是一个固定的值，它会根据宽高比（aspectRatio）动态计算出来，以适应其容器的大小。
    fluid: true,
    //-----------------------------
    // inactivityTimeout就是用来定义多少毫秒用户不操作，播放器将进入非活动状态。如果设置值为0，表示播放器永远不会进入非活动状态。
    // 可以通过监听useractive来判断播放器是否进入活动状态。
    inactivityTimeout: 4000,
    //-----------------------------

    nativeControlsForTouch: false,
    normalizeAutoplay: false,
    noUITitleAttributes: false,
    responsive: false,
    // playbackRates: [0.5, 1, 1.5, 2],
    // liveui: true,
    breakpoints: {},
    // liveui: true,
    controlBar: {
        //当前时间和持续时间的分隔符
        timeDivider: false,
        //显示持续时间
        durationDisplay: true,
        //点播流时，播放进度条，seek控制
        progressControl: true,
        //是否显示剩余时间功能
        remainingTimeDisplay: true,
        //全屏按钮
        fullscreenToggle: false,
        //播放暂停按钮
        playToggle: true,
        //音量控制
        volumeMenuButton: false,
        //当前播放时间
        currentTimeDisplay: true,
        //直播流时，显示LIVE
        // liveDisplay: true,
        //播放速率，当前只有html5模式下才支持设置播放速率
        // playbackRateMenuButton: true
        children: [ //children数组这是controlbar的子元素顺序

            {
                name: "playToggle"
            },
            {
                name: "currentTimeDisplay"
            },
            {
                name: "progressControl"
            },
            {
              name: "durationDisplay"
            },
            {
                name: "volumePanel"
            },
            // {
            //     name: "PictureInPictureToggle",
            // },
            {
                name: "FullscreenToggle"
            },
            // {
            //   name: "playbackRateMenuButton",
            //   playbackRates: [0.5, 1, 1.5, 2]
            // },
        ]
    },
    aspectRatio:'16:9',
};

const liveOptions = {
    // 布尔类型 false ，等同于 video 标签上没有 autoplay 属性。表示不用自动播放
    // 布尔类型 true ，等同于在video标签上添加了autoplay 属性，表示需要自动播放
    // 字符串类型 ‘muted’, 将会把播放器设置成静音，然后调用play() 实现自动播放
    // 字符串类型 ‘play’, 将会调用play()实现自动播放。
    // 字符串类型 ‘any’, 将会调用play实现自动播放，如果不能播放，则会把播放器设置成静音状态，再调用play()
    autoplay: false,
    //-----------------------------
    // 控制条，默认是true，设置为false后，将隐藏控制条。
    controls: true,
    //-----------------------------
    // 循环播放
    loop: false,
    //-----------------------------
    // 封面，值为图片的url链接
    // poster: '',
    //-----------------------------
    // 静音
    muted: false,
    //-----------------------------
    // ‘auto’ 立即下载视频资源（如果浏览器支持的话），有些手机浏览器节省带宽，会禁止提前下载。
    // ‘metadata’ 只下载视频的 meta 数据，meta 数据包含视频的时长和大小，有时候是通过下载几帧视频数据来得到meta的相关信息。
    // ‘none’ 不用提前下载任何数据，只有用户点击播放后才开始下载数据
    preload: 'metadata',
    //-----------------------------
    // 视频的url链接
    // src: '',
    //-----------------------------
    // 设置为true，播放器的的大小将不再是一个固定的值，它会根据宽高比（aspectRatio）动态计算出来，以适应其容器的大小。
    fluid: true,
    //-----------------------------
    // inactivityTimeout就是用来定义多少毫秒用户不操作，播放器将进入非活动状态。如果设置值为0，表示播放器永远不会进入非活动状态。
    // 可以通过监听useractive来判断播放器是否进入活动状态。
    inactivityTimeout: 4000,
    //-----------------------------

    nativeControlsForTouch: false,
    normalizeAutoplay: false,
    noUITitleAttributes: false,
    responsive: false,
    // playbackRates: [0.5, 1, 1.5, 2],
    // liveui: true,
    breakpoints: {},
    // liveui: true,
    controlBar: {
        //当前时间和持续时间的分隔符
        timeDivider: false,
        //显示持续时间
        durationDisplay: false,
        //点播流时，播放进度条，seek控制
        progressControl: false,
        //是否显示剩余时间功能
        remainingTimeDisplay: false,
        //全屏按钮
        fullscreenToggle: false,
        //播放暂停按钮
        playToggle: false,
        //音量控制
        volumeMenuButton: true,
        //当前播放时间
        currentTimeDisplay: true,

        //直播流时，显示LIVE
        // liveDisplay: true,
        //播放速率，当前只有html5模式下才支持设置播放速率
        // playbackRateMenuButton: true
        children: [ //children数组这是controlbar的子元素顺序
            {
                name: "currentTimeDisplay"
            },
            {
                name: "volumePanel"
            },
            // {
            //     name: "playToggle"
            // },


            // {
            //     name: "progressControl"
            // },
            {
                name: "PictureInPictureToggle",
            },
            {
                name: "FullscreenToggle"
            },
            // {
            //   name: "durationDisplay"
            // },

            // {
            //   name: "playbackRateMenuButton",
            //   playbackRates: [0.5, 1, 1.5, 2]
            // },
            // {
            //   name: "FullscreenToggle"
            // }
        ]
    },
    aspectRatio:'16:9',
};

export const myMediaConfig = {
    liveOptions, normalOptions
}