import axios from 'axios'
import md5 from "js-md5"
import {utils} from "../utils"
import {evnConfig} from "../evnConfig";


//当返回这两个错误码时需要重新获取token
//验证授权失败 被强制下线
const ACCOUNT_NO_LOGIN = 10005;
//登录过期
const LOGOUT_EXPIRE = 11010;

// 请求令牌
function AppAuthToken() {
  this.appAuthToken = 'null'
  this.get = function () {
    if (this.appAuthToken) return this.appAuthToken;
    return this.request()
  };
  this.request = function () {
    return this.appAuthToken
  };
  this.refresh = function (token) {
    this.appAuthToken = token;
  }
}

const appAuthToken = new AppAuthToken();


function wxRequestBody(method, bizContent) {
  this.appId = evnConfig.appAPIConfig.APP_ID();
  this.method = method;
  this.format = 'JSON';
  this.charset = 'utf-8';
  this.signCode = '';
  this.timestamp = utils.toDate();
  this.version = '1.0';
  this.appAuthToken = '';
  this.bizContent = bizContent;
  this.encrypt = function () {
    let keys = Object.keys(this).sort();
    let signCode = '';
    keys.forEach(key => {
      if (key !== 'encrypt') signCode += this[key]
    });
    signCode += evnConfig.appAPIConfig.SECRET();
    this.signCode = md5(signCode);
  }
}

/**
 * 响应体
 * @constructor
 */
function wxResponseBody() {
  this.code = "";
  this.subCode = "";
  this.msg = "";
  this.subMsg = "";
  this.timestamp = "";
  this.sign = "";
  this.bizContent = "";
  this.token = "";
  this.check = function () {
    if (this.code !== 0) {
      if (this.code === ACCOUNT_NO_LOGIN || this.code === LOGOUT_EXPIRE) {
        //发送一条总线命令，让其通知想知道的组件
      }
      throw {code: this.code, msg: this.msg};
    }
    let keys = Object.keys(this).sort();
    let signCode = '';
    keys.forEach(key => {
      if (
          key !== 'fillBody' &&
          key !== 'getData' &&
          key !== 'check' &&
          key !== 'sign'
      ) signCode += this[key]
    });
    signCode += evnConfig.appAPIConfig.SECRET();
    if (md5(signCode) !== this.sign) {
      throw {code: -1, msg: '签名错误'};
    }

    // 判断token是否需要更新
    if (this.token && appAuthToken.appAuthToken !== this.token) {
      appAuthToken.refresh(this.token)
    }
  };
  this.getData = function () {
    return JSON.parse(this.bizContent)
  }
}

// `transformRequest` 允许在向服务器发送前，修改请求数据
function wxTransformRequest(data) {
  if (typeof (data.bizContent) === 'object') data.bizContent = JSON.stringify(data.bizContent);
  const requestBody = new wxRequestBody(data.method, data.bizContent);
  requestBody.encrypt();
  return JSON.stringify(requestBody)

}

// `transformResponse` 在传递给 then/catch 前，允许修改响应数据
function wxTransformResponse(data) {
  // 当接口返回新的token时需要将token回传给app
  // const responseBody = new ResponseBody();
  // utils.fillData(JSON.parse(data), responseBody);
  // responseBody.check();
  // return responseBody.getData()
  return data
}

export function httpWx(config) {
  const instance = axios.create({
    baseURL: evnConfig.getWechatAPIBaseUrl(),
    method: "POST",
    timeout: 30000,
    headers: {'Content-Type': 'application/json'},
    transformRequest: wxTransformRequest,
    transformResponse: wxTransformResponse
  })

  //请求拦截
  instance.interceptors.request.use(config=>{
    return config
  }, err=>{
    throw err;
  })
  //响应拦截
  instance.interceptors.response.use(res=>{
        return JSON.parse(res.data)
      }, err=>{
        throw err;
      }
  )
  return instance(config)
}