import CUploadImg from "@/components/CUploadImg";
export default {
  name: "CUserContext",
  components: {
    CUploadImg
  },
  computed: {
    getImg() {
      if (this.mInfo.userImg === '') {
        return require('../../assets/defaultUser.png');
      } else {
        return this.mInfo.userImg;
      }
    }
  },
  data() {
    return {
      userInfo: null,
      mInfo: {
        userId: '',
        userName: '',
        userImg: '',
        identifier: ''
      }
    };
  },
  created() {
    this.userInfo = this.$userInfo;
  },
  mounted() {
    this.mInfo.userId = this.userInfo.userId;
    this.mInfo.userName = this.userInfo.userName;
    this.mInfo.identifier = this.userInfo.identifier;
    this.mInfo.userImg = this.userInfo.userImg;
    console.log("CUserContext:", this.mInfo.userImg);
  },
  methods: {
    reset() {
      this.mInfo.userId = this.userInfo.userId;
      this.mInfo.userName = this.userInfo.userName;
      this.mInfo.identifier = this.userInfo.identifier;
      this.mInfo.userImg = this.userInfo.userImg;
      this.$refs.CUploadImg.reset();
    },
    // 上传图片到OSS
    uploadFileToOss() {
      return this.$refs.CUploadImg.uploadFileToOss();
    }
  }
};