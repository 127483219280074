import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_CLogin = _resolveComponent("CLogin");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_router_view, null, {
    default: _withCtx(({
      Component
    }) => [!_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
      key: 0
    })) : _createCommentVNode("", true), (_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
      key: 0
    })) : _createCommentVNode("", true)], 1024))]),
    _: 1
  }), _createVNode(_component_CLogin, {
    ref: "CLogin",
    onSubmit: $options.onSubmit,
    onSendSms: $options.onSendSms
  }, null, 8, ["onSubmit", "onSendSms"])]);
}